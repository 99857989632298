/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useRef } from 'react';
import * as echarts from 'echarts';

export type LineChartSeries = {
    name: string;
    data: number[];
    type?: 'line';
    smooth?: number;
    areaStyle?: any;
    lineStyle?: any;
    symbolSize?: number;
    z?: number;
    emphasis?: any;
    yAxisIndex?: number;
}

export type IProps = {
    height: number;
    isLoading?: boolean;
    xAxisData: string[];
    series: LineChartSeries[];
    yAxis?: echarts.EChartOption.YAxis[];
    xAxis?: echarts.EChartOption.XAxis;
    legend?: Partial<echarts.EChartOption.Legend>;
    grid?: Partial<echarts.EChartOption.Grid>;
    tooltip?: Partial<echarts.EChartOption.Tooltip>;
    onClickPoint?: (params: any) => void;
    tooltipFormatter?: (params: any) => string;
}

const PSLineChart: React.FC<IProps> = (props) => {
    const {
        height,
        isLoading = false,
        xAxisData = [],
        series = [],
        yAxis = [],
        xAxis = {},
        legend,
        grid,
        tooltip,
        onClickPoint,
        tooltipFormatter,
    } = props;

    const ref = useRef(null);
    const chartRef = useRef<echarts.ECharts | null>(null);

    useLayoutEffect(() => {
        if (!ref.current || chartRef.current) return;

        let resizeTimeout: NodeJS.Timeout;
        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                chartRef.current?.resize();
            }, 10);
        };

        const timer = setTimeout(() => {
            if (!ref.current) return;

            chartRef.current = echarts.init(ref.current, null, {
                renderer: 'svg',
            });

            if (onClickPoint) {
                chartRef.current.on('click', onClickPoint);
            }

            chartRef.current.setOption({
                tooltip: {
                    trigger: 'axis',
                    formatter: tooltipFormatter,
                    padding: 16,
                    backgroundColor: 'white',
                    borderWidth: 0,
                    extraCssText: 'box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);',
                    show: !isLoading,
                    ...tooltip,
                },
                legend: {
                    bottom: 0,
                    icon: 'circle',
                    itemWidth: 8,
                    itemHeight: 8,
                    textStyle: {
                        fontSize: 12,
                        fontFamily: 'DM Sans',
                        color: '#C0C0C2',
                        fontWeight: 400,
                        fontStyle: 'normal'
                    },
                    itemGap: 24,
                    ...legend,
                },
                grid: {
                    left: '100px',
                    right: '100px',
                    top: '20px',
                    bottom: legend ? '40px' : '20px',
                    containLabel: true,
                    ...grid,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: [],
                    axisLine: {
                        lineStyle: { color: '#E5E7EB' }
                    },
                    axisLabel: {
                        color: '#C0C0C2',
                        fontFamily: 'DM Sans',
                        fontSize: 12,
                        fontWeight: 400
                    }
                },
                yAxis: [{
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            color: '#E5E7EB'
                        }
                    },
                    axisLabel: {
                        color: '#C0C0C2',
                        fontFamily: 'DM Sans',
                        fontSize: 12,
                        fontWeight: 400
                    }
                }],
                series: [{
                    type: 'line',
                    data: []
                }]
            });
        }, 0);

        const resizeObserver = new ResizeObserver(handleResize);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            clearTimeout(timer);
            clearTimeout(resizeTimeout);
            resizeObserver.disconnect();
            window.removeEventListener('resize', handleResize);
            chartRef.current?.dispose();
            chartRef.current = null;
        };
    }, []);

    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            chartRef.current?.setOption({
                tooltip: {
                    trigger: 'axis',
                    formatter: tooltipFormatter,
                    padding: 16,
                    backgroundColor: 'white',
                    borderWidth: 0,
                    extraCssText: 'box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);',
                    show: !isLoading,
                    ...tooltip,
                },
                legend: {
                    show: !isLoading,
                    bottom: 0,
                    icon: 'circle',
                    itemWidth: 8,
                    itemHeight: 8,
                    textStyle: {
                        fontSize: 12,
                        fontFamily: 'DM Sans',
                        color: '#C0C0C2',
                        fontWeight: 400,
                        fontStyle: 'normal'
                    },
                    itemGap: 24,
                    ...legend,
                },
                grid: {
                    left: '100px',
                    right: '100px',
                    top: '20px',
                    bottom: legend ? '40px' : '20px',
                    containLabel: true,
                    ...grid,
                },
                xAxis: {
                    show: !isLoading,
                    type: 'category',
                    boundaryGap: false,
                    data: isLoading ? Array(10).fill('').map((_, i) => `Day ${i + 1}`) : xAxisData,
                    axisLine: {
                        lineStyle: { color: '#E5E7EB' }
                    },
                    axisLabel: {
                        color: '#C0C0C2',
                        fontFamily: 'DM Sans',
                        fontSize: 12,
                        fontWeight: 400,
                    },
                    ...xAxis,
                },
                yAxis: isLoading 
                    ? [{
                        show: false,
                        type: 'value',
                        splitLine: {
                            lineStyle: {
                                color: '#E5E7EB'
                            }
                        },
                        axisLabel: {
                            color: '#C0C0C2',
                            fontFamily: 'DM Sans',
                            fontSize: 12,
                            fontWeight: 400
                        }
                    }]
                    : yAxis.length > 0
                        ? yAxis.map(axis => ({
                            show: true,
                            axisLabel: {
                                color: '#C0C0C2',
                                fontFamily: 'DM Sans',
                                fontSize: 12,
                                fontWeight: 400
                            },
                            ...axis,
                        }))
                        : [{
                            show: true,
                            type: 'value',
                            splitLine: {
                                lineStyle: {
                                    color: '#E5E7EB'
                                }
                            },
                            axisLabel: {
                                color: '#C0C0C2',
                                fontFamily: 'DM Sans',
                                fontSize: 12,
                                fontWeight: 400
                            }
                        }],
                series: isLoading 
                    ? [{
                        type: 'line',
                        name: 'Loading',
                        data: Array(10).fill(0).map((_, i) => {
                            const baseValue = i * 35 + 100;
                            const randomFactor = Math.sin(i * 1.5) * 50;
                            const volatility = Math.random() * 30 - 15;
                            const endSpike = i >= 8 ? (i - 7) * 80 : 0;
                            return baseValue + randomFactor + volatility + endSpike;
                        }),
                        itemStyle: { 
                            color: '#DDDEE3'
                        },
                        lineStyle: { 
                            color: '#DDDEE3',
                            width: 10
                        },
                        smooth: 0.2,
                        symbol: 'none',
                        areaStyle: undefined
                    }]
                    : series.map(s => ({
                        type: 'line',
                        itemStyle: {
                            color: s.lineStyle?.color || undefined
                        },
                        ...s
                    }))
            }, { 
                notMerge: false,
                lazyUpdate: true 
            });
        }, 0);

        return () => {
            clearTimeout(timer);
        };
    }, [isLoading, xAxisData, series, yAxis, legend, tooltipFormatter]);

    return <div ref={ref} style={{ height, width: '100%' }} />;
};

export default PSLineChart;
