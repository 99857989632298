/** @jsxImportSource @emotion/react */
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import Chip from '../../Chip/Chip';
import {RefCallBack} from "react-hook-form";

type IProps = {
    label?: string;
    defaultValue?: string[];
    minWidth?: number | string;
    value?: string[];
    placeholder?: string;
    onChange?: (newValue: string[]) => void;
    ref: RefCallBack;
    disabled?: boolean;
}

const ChipsInput: React.FC<IProps> = (props) => {
    const { label, defaultValue, ref, minWidth = 120, onChange, value, placeholder, disabled, ...autoCompleteProps } = props;

    return (
        <Autocomplete
            {...autoCompleteProps}
            multiple
            freeSolo
            defaultValue={defaultValue}
            options={[]}
            value={value}
            size="small"
            disabled={disabled}
            onChange={(event, newValue) => {
                onChange && onChange(newValue);
            }}
            renderTags={(value, props) =>
                value.map((option, index) => (
                    <Chip
                        {...props({ index })}
                        key={option + index}
                        label={option}
                        size='small'
                        variant='outlined'
                    />
                ))
            }
            renderInput={(params) => <TextField {...params} label={label} size='small' placeholder={placeholder} inputRef={ref} />}
            style={{ minWidth: minWidth }}
        />
    )
}

export default ChipsInput;