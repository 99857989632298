import { create } from 'zustand';
import { RouteObject } from 'react-router-dom';


interface TableHelpersState {
    routes: RouteObject[];
    setRoutes: (value: RouteObject[]) => void;
}

const useRoutesStore = create<TableHelpersState>((set) => ({
    routes: [],
    setRoutes: (value) => set(() => ({ routes: value })),
}));

export default useRoutesStore;
