/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const HomegrownApplicationsAPIStyle = {
    self: css``,
    titleContainer: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--color-black-30);
        padding-bottom: 20px;
        margin-bottom: 20px;
    `,
    apiKey: css`
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
    `,
    radioGroup: css`
        margin-bottom: 15px;
    `,
}