/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import {Text, PSBadge, Icon} from '../../ui-kit'
import { PSPercentageChangeBadgeStyles } from './PSPercentageChangeBadge.css';
import { ColorThemeKeys } from "../../styles";

type TTheme = 'dark' | 'light' | 'default';

type IProps = {
    theme?: TTheme;
    value?: number | string;
    direction: "up" | "down";
}

const themeToColorMap: Record<TTheme, { text: ColorThemeKeys, background: ColorThemeKeys  }> = {
    dark: {
        text: 'white',
        background: 'purple-80',
    },
    light: {
        text: 'purple-80',
        background: 'white',
    },
    default: {
        text: 'white',
        background: 'black-50',
    },
}

const PSPercentageChangeBadge: FC<IProps> = (props) => {
    const { value, direction, theme = 'default' } = props;

    if (!value) return null;

    return (
        <PSBadge
            size='large'
            backgroundColor={themeToColorMap[theme].background}
        >
            <div css={PSPercentageChangeBadgeStyles.self}>
                <Icon
                    iconSize={15}
                    iconName={direction === 'up' ? 'PSArrowUpIcon' : 'PSArrowDownIcon'}
                    color={themeToColorMap[theme].text}
                    css={PSPercentageChangeBadgeStyles.icon}
                />
                {
                    typeof value === "number" ?
                        <Text color={themeToColorMap[theme].text}>{`${value}%`}</Text> : <Text color={themeToColorMap[theme].text}>{`${value}`}</Text>
                }
            </div>
        </PSBadge>
    )
}

export default PSPercentageChangeBadge;