/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TOverviewVariants } from "./types";

const stylesByVariant = {
    dark: {
        selfBorder: '',
        backgroundColor: 'var(--color-gradient-purple)',
        topContainerBorderBottom: '1px solid var(--color-white-15)',
    },
    light: {
        selfBorder: '1px solid var(--color-black-30)',
        backgroundColor: 'var(--color-white)',
        topContainerBorderBottom: '1px solid var(--color-black-30)',
    },

    pointyDark: {
        selfBorder: '',
        backgroundColor: 'var(--color-gradient-alt_purple)',
        topContainerBorderBottom: '0',
    },
}

export const PSOverviewStyle = (variant: TOverviewVariants) => ({
    wrapper: (isWideScreen: boolean) => css`
        position: relative;
        ${
                variant === 'pointyDark' && `
                    height: 100%;
                    
                    &::after {
                        content: "";
                        z-index: 10;
                        position: absolute;
                        bottom: 0;
                        right: ${isWideScreen ? '-98%' :'-96%'};
                        width: 100%;
                        height: 100%;
                        background: var(--color-gradient_parts-purple-light);
                        clip-path: path('M0 0H7.84451C13.9781 0 19.4937 3.73426 21.7717 9.42914L44.5433 66.3583C47.4042 73.5106 47.4042 81.4894 44.5433 88.6417L21.7717 145.571C19.4937 151.266 13.9781 155 7.84451 155H0V0Z');
                    }`
        }

        
    `,
    self: (minWidth: number | undefined, maxWidth: number | undefined) => css`
        ${minWidth ? css`min-width: ${minWidth}px;` : ''}
        ${maxWidth ? css`max-width: ${maxWidth}px;` : ''}
        width: 100%;
        border: ${stylesByVariant[variant].selfBorder};
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        background: ${stylesByVariant[variant].backgroundColor};
        min-height: 155px;
        container-type: inline-size;

        ${variant === 'pointyDark' ? css`height: 100%;` : ''}
    `,
    glow: css`
        z-index: unset;
        position: absolute;
        right: -18%;
        top: 20%;
        background: ${variant === "dark" ? 'var(--color-white-15)' : '#ba95e31f'}; // TODO - add opacity to design system somehow.
        -webkit-backdrop-filter: blur(37px);
        -ms-filter: blur(37px);
        filter: blur(37px);
    `,
    topContainer: css`
        min-height: 115px;
        width: 100%; 
        border-bottom: ${stylesByVariant[variant].topContainerBorderBottom}; 
        display: flex;
        align-items: center;

        ${variant === 'pointyDark' ? css`height: 100%;` : ''}
    `,
    contentWrapper: (maxContentWidth: number | undefined) => css`
        display: flex;
        justify-content: space-between;
        padding: 0 25px 0 30px;
        width: 100%;
        gap: 30px;
        ${maxContentWidth ? css`max-width: ${maxContentWidth}px;` : ''}
    `,
    contentTextContainer: css`
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 70px;
        gap: 5px;
    `,
    textContent: css`
        color: ${variant === "light" ? 'var(--color-black)' : 'var(--color-white)'};
        font-weight: ${variant === "light" ? 400 : 700};
        line-height: unset;
        margin-bottom: 5px;
        z-index: 5;
    `,
    numberContent: css`
        color: ${variant === "light" ? 'var(--color-black)' : 'var(--color-white)'};
        font-weight: 700;
        font-size: 45px;
        line-height: 40px;
        z-index: 5;
    `,
    iconWrapper: css`
        display: flex;
        justify-content: center;
        align-items: ${variant === 'pointyDark' ? 'start' : 'center'};
    `,
    iconContainer: css`
        width: 70px;
        height: 70px;
        border-radius: 100%;
        border: ${variant === "light" ? '2px solid var(--color-purple-50)' : ''};
        background: var(--color-white);
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    badgeContainer: css`
        align-self: flex-end;
        z-index: 10;
        margin-left: 5px;
    `,
    skeletonContainer: css`
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
    `,
    skeleton: css`
        background: ${variant === "light" ? 'var(--color-black-30)' : 'var(--color-white)'};
    `,
    numberSkeletonContainer: css`
        width: 100%;
        max-width: 240px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    numberAndBadgeContainer: css`
        min-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    numberAndPopperContainer: css`
        position: relative; 
        width: 100%;
    `,
    popperWrapperCss: css`
        width: 100%; 
        height: 215%;  
        position: absolute; 
        top: -85%; 
        left: -10%;
        border: 10px solid transparent; 
        border-top: 0; 
        box-sizing: content-box;
        z-index: 20;
    `,
    footerContainer: css`
        z-index: 10;
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 25px 0 30px;
    `,
    errorContainer: css`
        border: 1px solid var(--color-black-30); 
        border-radius: 15px;
        background: var(--color-white);
    `,
    popper: css`
        max-Width: 360px
    `,
});