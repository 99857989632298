/** @jsxImportSource @emotion/react */
import { RadioGroup, Step, StepLabel, Stepper } from '@mui/material';
import React, { useState } from 'react';
import { Icon, PSButton, PSCheckboxCard, PSError, PSImageBox, PSRadioCard, PSStepConnector, PSStepIconComponent, Text } from '../../ui-kit';
import { css } from '@emotion/react';
import { GenAiMgmtOnboardingStyle } from './GenAiMgmtOnboarding.css';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { graphql, createGraphQLClient } from '../../gql';
import {
    SecurityApproach,
    GenAiAppMgmtOnboardingInput,
    GetAllApplicationsQueryQuery,
} from '../../gql/generated/graphql';
import AddCustomApplicationDialog from './AddCustomApplicationDialog';
import { toast } from 'react-toastify';
import AddVerifiedApplicationModal from './AddVerifiedApplicationModal';
import { useCheckPermission } from "../../hooks/usePermissionCheck";

type IProps = {};

export type TCustomApp = {
    id: string;
    name: string;
    domain: string;
}

const steps = [
    {
        title: 'Security Approach',
        secondary: 'Block all vs. Approve all apps'
    },
    {
        title: 'Exceptions',
        secondary: 'For specific apps'
    },
    {
        title: 'Summary',
        secondary: 'Review and confirm'
    }
];

const getAllApplications = graphql(`
    query GetAllApplicationsQuery($input: GetGenAiApplicationsInput!) {
        getGenAiApplications(input: $input) {
            genAiApplicationsWithAdditionStatus {
                additionStatus
                wasModified
                genAiApplication {
                    vendor
                    logo
                    isCustom
                    id
                    faviconUrl
                    name
                    domain
                    type
                }
            }
            securityApproach
            count
        }
    }
`);

const performOnboarding = graphql(`
    mutation PerformOnboarding($input: genAiAppMgmtOnboardingInput!) {
        genAiAppMgmtOnboarding(input: $input)
    }
`);

const ONBOARDING_APPLICATIONS_IDS = [
    '3b56d571-56d0-47d6-a831-c1e443f7de40', //CHATGPT,
    'c02e0419-c23f-428f-aa4b-cca8a6bf8ae7', //Gemini,
    '7a4b3b4b-7440-461f-ae90-8601809300d7', //Claude,
    'e4528d98-6c6a-4d04-8215-6daab92e6a27', //Poe,
    'f3c3ef5c-28d8-494c-b39a-efb8fc138258', //PromptHero,
    '2da97f94-9257-451b-887f-1064bb8fa449', //Microsoft Copilot,
    'ac8cfa04-0e30-4c93-b8b2-a3354019502a', //Perplexity,
    '9fea4352-34e5-4b35-830f-11b69ef780b3' //ChatPDF
]


const GenAiMgmtOnboarding: React.FC<IProps> = (props) => {
    const { } = props;

    const queryClient = useQueryClient();

    const [activeStep, setActiveStep] = useState(0);
    const [securityApproach, setSecurityApproach] = useState<SecurityApproach | null>(null);
    const [selectedApplicationsIds, setSelectedApplicationsIds] = useState<Record<string, boolean | null>>({});
    const [customApplications, setCustomApplications] = useState<TCustomApp[]>([]);
    const [isAddApplicationsModalOpen, setIsAddApplicationModalOpen] = useState(false);
    const [isCustomAppDialogOpen, setIsCustomAppDialogOpen] = useState(false);
    const hasPermission = useCheckPermission('ps.genAiApplications.update.globalSettings');

    const handleNext = () => {
        if (activeStep === steps.length) return;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleComplete = async () => {
        const exceptionGenAiApplicationsIds = Object.keys(selectedApplicationsIds).filter(key => selectedApplicationsIds[key]);
        // const customApplicationsFiltered: Array<CustomGenAiAppInput> = customApplications.map(app => ({ name: app.name, domain: app.domain }));

        const variables: GenAiAppMgmtOnboardingInput = {
            securityApproach: securityApproach!,
            // exceptionCustomGenAiApplications: customApplicationsFiltered,
            exceptionGenAiApplicationsIds
        }

        await performOnboardingAsync(variables);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setTimeout(() => {
            queryClient.invalidateQueries({ queryKey: ['onboarding-status'] });
        }, 3000)
    }

    const handleBack = () => {
        if (activeStep === 0) return;
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const addCustomApp = (name: TCustomApp['name'], domain: TCustomApp['domain']) => {
        const newApp: TCustomApp = {
            name,
            domain,
            id: Math.random().toString(36).substring(7)
        }
        setCustomApplications(prev => [...prev, newApp]);
    }

    const addAppAlreadyExists = (application: NonNullable<NonNullable<GetAllApplicationsQueryQuery['getGenAiApplications']['genAiApplicationsWithAdditionStatus']>[number]>) => {
        if (selectedApplicationsIds[application.genAiApplication.id] !== undefined) {
            setSelectedApplicationsIds(prev => ({ ...prev, [application.genAiApplication.id]: true }))
            return;
        };

        setSelectedApplicationsIds(prev => ({ ...prev, [application.genAiApplication.id]: true }))
        setGenAiApps(prev => [...prev, application]);
    }

    const removeCustomApp = (id: TCustomApp['id']) => {
        setCustomApplications(prev => prev.filter(app => app.id !== id));
    }

    const [genAiApps, setGenAiApps] = useState<NonNullable<GetAllApplicationsQueryQuery['getGenAiApplications']['genAiApplicationsWithAdditionStatus']>>([]);

    const { status: genAiAppsStatus } = useQuery({
        queryKey: ['get-onboarding-applications'],
        queryFn: async ({ signal }) => {
            const client = createGraphQLClient(signal);
            const { getGenAiApplications } = await client.request(getAllApplications, {
                input: {
                    Filter: {
                        applicationsId: ONBOARDING_APPLICATIONS_IDS
                    },
                    Pagination: {
                        pageNumber: 1,
                        pageSize: ONBOARDING_APPLICATIONS_IDS.length
                    }
                }
            });

            const ids = getGenAiApplications.genAiApplicationsWithAdditionStatus?.map(x => {
                return {
                    [x.genAiApplication.id]: false
                }
            });

            if (!ids) return getGenAiApplications

            setSelectedApplicationsIds(prev => {
                return {
                    ...prev,
                    ...Object.assign({}, ...ids)
                }
            })

            setGenAiApps(getGenAiApplications.genAiApplicationsWithAdditionStatus || []);

            return getGenAiApplications;
        },
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    })

    const { mutateAsync: performOnboardingAsync, isPending: isPerformOnboardingLoading } = useMutation({
        mutationKey: ['perform-onboarding'],
        mutationFn: async (variables: GenAiAppMgmtOnboardingInput) => {
            const client = createGraphQLClient();
            const { genAiAppMgmtOnboarding } = await client.request(performOnboarding, {
                input: {
                    ...variables
                }
            });
            return genAiAppMgmtOnboarding;
        },
        onSuccess: () => {
            toast.success('You will now be redirected to the genAI app management page');
        },
        onError: (error) => {
            toast.error('An error occurred while saving data');
        }
    })

    const selectedAppsFiltered = Object.keys(selectedApplicationsIds).filter(key => selectedApplicationsIds[key]);

    return (
        <div css={GenAiMgmtOnboardingStyle.self}>
            <Stepper css={GenAiMgmtOnboardingStyle.stepper} activeStep={activeStep} connector={<PSStepConnector />}>
                {steps.map((item, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step key={item.title} {...stepProps}>
                            <StepLabel StepIconComponent={props => <PSStepIconComponent {...props} index={index + 1} />}>
                                <Text variant='bold'>{item.title}</Text>
                                <Text variant='small'>{item.secondary}</Text>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === 0 && (
                <div css={[GenAiMgmtOnboardingStyle.stepContainer, GenAiMgmtOnboardingStyle.contentContainer]}>
                    <Text variant="header2">Choose your security approach for dealing with genAI apps</Text>
                    <Text textCss={css`margin-top: 15px;`}>Would you prefer to <b>block all</b> and approve selectively, or <b>allow all</b> and block only risky applications?</Text>
                    <Text>Note that <b>you will be able to change these settings any time you want.</b></Text>

                    <RadioGroup
                        row
                        css={GenAiMgmtOnboardingStyle.securityApproachContainer}
                        onChange={(_, value) => setSecurityApproach(value as SecurityApproach)}
                        value={securityApproach}
                    >
                        <PSRadioCard
                            value={SecurityApproach.BlockAll}
                            title='Block All GenAI apps'
                            iconName='PSBlockIcon'
                            selectedBorderColor='red-50'
                            description='Except a few verified apps you want to approve'
                            disabled={!hasPermission}
                        />
                        <PSRadioCard
                            value={SecurityApproach.InspectAll}
                            title='Approve All GenAI apps'
                            iconName='PSDoneAllIcon'
                            selectedBorderColor='green-50'
                            description='Except a few risky apps you want to block'
                            disabled={!hasPermission}
                        />
                    </RadioGroup>
                </div>
            )}

            {activeStep === 1 && (
                <div css={[GenAiMgmtOnboardingStyle.stepContainer, GenAiMgmtOnboardingStyle.contentContainer]}>
                    {securityApproach === SecurityApproach.BlockAll && (
                        <>
                            <Text variant="header2">Choose Verified Applications to Approve</Text>
                            <Text textCss={css`margin-top: 15px;`}>You can now actively select and approve a few trusted genAI apps for use within your organization.</Text>
                            <Text>This gives you control over the specific generative AI applications your organization and its members can use.</Text>
                            <Text textCss={css`margin-top: 10px;`} color='red-50'>Please be aware that approving an application will automatically apply</Text>
                            <Text variant='bold' color='red-50'>to all employees using the Prompt Security extension.</Text>
                        </>
                    )}
                    {securityApproach === SecurityApproach.InspectAll && (
                        <>
                            <Text variant="header2">Choose Risky Applications to Block</Text>
                            <Text textCss={css`margin-top: 15px;`}>You can now actively select and block certain risky genAI apps that may pose a risk to your organization.</Text>
                            <Text>This gives you control over the specific generative AI applications your organization and its members should avoid.</Text>
                            <Text textCss={css`margin-top: 10px;`} color='red-50'>Please be aware that blocking an application will automatically apply</Text>
                            <Text variant='bold' color='red-50'>to all employees using the Prompt Security extension.</Text>
                        </>
                    )}

                    <div css={css`max-width: 1456px;`}>
                        <div css={css`margin-top: 65px;`}>
                            {genAiAppsStatus === 'success' && <Text variant='bold' textCss={css`width: fit-content;`} onClick={() => setIsAddApplicationModalOpen(true)}>+ Add Application</Text>}
                        </div>
                        <div css={css`display: flex; justify-content: center; align-items: center; flex-wrap: wrap; gap: 32px; margin-top: 45px;`}>
                            {genAiAppsStatus === 'error' && (
                                <PSError message='Error while fetching applications' />
                            )}
                            {genAiAppsStatus === 'pending' && (
                                Array(8).fill(0).map((_, index) => (
                                    <PSCheckboxCard isLoading key={index} />
                                ))
                            )}
                            {genAiAppsStatus === 'success' &&
                                <React.Fragment>
                                    {customApplications.map((item: TCustomApp) => (
                                        <PSCheckboxCard
                                            key={item.id}
                                            checked={true}
                                            value={item.id}
                                            selectedBorderColor={securityApproach === SecurityApproach.BlockAll ? 'green-50' : 'red-50'}
                                            title={item.name}
                                            iconUrl={`https://www.${item.domain}/favicon.ico`}
                                            url={`https://${item.domain}`}
                                            onRemove={() => removeCustomApp(item.id)}
                                            isCustom
                                        />
                                    ))}
                                    {genAiApps.map((item) => {
                                        const { id, domain, isCustom, name, logo, faviconUrl } = item.genAiApplication;
                                        return (
                                            <PSCheckboxCard
                                                key={id}
                                                checked={Boolean(selectedApplicationsIds[id])}
                                                onChange={(_, checked) => setSelectedApplicationsIds(prev => ({ ...prev, [id]: checked }))}
                                                value={id}
                                                selectedBorderColor={securityApproach === SecurityApproach.BlockAll ? 'green-50' : 'red-50'}
                                                iconUrl={isCustom ? `https://www.${domain}/favicon.ico` : (logo || faviconUrl || undefined)}
                                                title={name}
                                                url={`https://${domain}`}
                                                isCustom={isCustom}
                                            />
                                        )
                                    })}
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            )}

            {(activeStep === 2 || activeStep === steps.length) && (
                <div css={[GenAiMgmtOnboardingStyle.contentContainer]}>

                    {activeStep === steps.length && (
                        <div css={css`margin-top: 50px; margin-inline: auto; width: min(480px, 100%);`}>
                            <Text variant='header'>You’re all set up!</Text>
                            <Text textCss={css`margin-top: 5px;`}>Data was successfully saved.</Text>
                            <Text color='black-70' textCss={css`margin-top: 20px;`} variant='small'>You will be redirected to the extension management page in 3 seconds.</Text>
                        </div>
                    )}

                    <div css={GenAiMgmtOnboardingStyle.summaryContainer}>
                        <Text color='purple-60' textCss={GenAiMgmtOnboardingStyle.onboardingSummaryText}>Onboarding Summary</Text>
                        <div css={[GenAiMgmtOnboardingStyle.headerSummaryTextContainer, customApplications.length === 0 && selectedAppsFiltered.length === 0 ? GenAiMgmtOnboardingStyle.summaryHeaderLine : null]}>
                            <Icon iconName={securityApproach === SecurityApproach.BlockAll ? "PSBlockIcon" : "PSDoneAllIcon"} iconSize={40} color="black-40" />
                            <Text variant='header'>
                                {securityApproach === SecurityApproach.BlockAll ? 'Block' : 'Approve'} All GenAI apps
                            </Text>
                        </div>

                        {customApplications.length === 0 && selectedAppsFiltered.length === 0 && (
                            <div css={GenAiMgmtOnboardingStyle.summaryAppsContainer(securityApproach === SecurityApproach.BlockAll ? 'green-5' : 'red-5')}>
                                <Text>
                                    {securityApproach === SecurityApproach.BlockAll
                                        ? activeStep === steps.length ? 'You’ve chosen to block all genAI applications.' :
                                            <>
                                                You’ve chosen to block all genAI applications. If you wish to approve specific verified genAI applications for internal use, please return to the
                                                <Text textCss={css`margin-left: 5px;`} inline onClick={handleBack}>previous page.</Text>
                                            </>
                                        : activeStep === steps.length ? 'No risky applications have been selected for blocking.' :
                                            <>
                                                No risky applications have been selected for blocking. If you wish to block any risky applications, please return to the
                                                <Text textCss={css`margin-left: 5px;`} inline onClick={handleBack}>previous page.</Text>
                                            </>
                                    }
                                </Text>
                            </div>
                        )}


                        {(customApplications.length > 0 || selectedAppsFiltered.length > 0) && (
                            <>
                                <Text textCss={GenAiMgmtOnboardingStyle.selectedText}>
                                    Except the following <b>{selectedAppsFiltered.length + customApplications.length} {securityApproach === SecurityApproach.BlockAll ? 'verified' : 'risky'} applications.</b>
                                </Text>
                                <div css={GenAiMgmtOnboardingStyle.summaryAppsContainer(securityApproach === SecurityApproach.BlockAll ? 'green-5' : 'red-5')}>
                                    <Text variant='bold' color={securityApproach === SecurityApproach.BlockAll ? 'green-50' : 'red-50'}>
                                        {securityApproach === SecurityApproach.BlockAll ? 'Verified applications to approve:' : 'Risky applications to block:'}
                                    </Text>
                                    {/* {customApplications.map(app => (
                                        <div css={GenAiMgmtOnboardingStyle.itemSummary} key={app.id}>
                                            <PSImageBox iconName='PSDefaultAiApplicationIcon' size={50} borderColor={securityApproach === SecurityApproach.BlockAll ? 'green-50' : 'red-50'} />
                                            <Text variant='bold' ellipsis>{app.name}</Text>
                                        </div>
                                    ))} */}
                                    {selectedAppsFiltered.map(appId => {
                                        const app = genAiApps?.find((item) => item.genAiApplication.id === appId);
                                        if (!app) return;
                                        const { isCustom, name, logo, faviconUrl } = app?.genAiApplication;
                                        return (
                                            <div css={GenAiMgmtOnboardingStyle.itemSummary} key={appId}>
                                                {isCustom && <PSImageBox iconName='PSDefaultAiApplicationIcon' size={50} borderColor={securityApproach === SecurityApproach.BlockAll ? 'green-50' : 'red-50'} />}
                                                {!isCustom && <PSImageBox size={50} imageUrl={logo || faviconUrl || undefined} borderColor={securityApproach === SecurityApproach.BlockAll ? 'green-50' : 'red-50'} />}
                                                <Text variant='bold' ellipsis>{name}</Text>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}

            <div css={GenAiMgmtOnboardingStyle.stepperButtons}>
                {(activeStep !== 0 && activeStep !== steps.length) && <PSButton onClick={handleBack} variant='flat'>Back</PSButton>}
                {activeStep !== steps.length && (
                    <>
                        {activeStep !== steps.length - 1 ?
                            <PSButton disabled={!securityApproach || !hasPermission} onClick={handleNext} variant='filled'>Next</PSButton>
                            : <PSButton disabled={!hasPermission} onClick={handleComplete} variant='filled' isLoading={isPerformOnboardingLoading}>Click to Confirm</PSButton>}

                    </>
                )}
            </div>

            {isAddApplicationsModalOpen && <AddVerifiedApplicationModal
                isOpen={isAddApplicationsModalOpen}
                onClose={() => setIsAddApplicationModalOpen(false)}
                onApplicationActionAdd={(applications) => {
                    setGenAiApps(prev => {
                        const currentApplicationsIds = prev.map(x => x.genAiApplication.id);
                        const newApplications = applications.filter(x => !currentApplicationsIds.includes(x.genAiApplication.id));
                        return [...prev, ...newApplications];
                    });
                    setSelectedApplicationsIds(prev => {
                        return {
                            ...prev,
                            ...Object.assign({}, ...applications.map((application) => ({ [application.genAiApplication.id]: true })))
                        }
                    })
                    setIsAddApplicationModalOpen(false);
                }}
            // openCustomAppModal={() => {
            //     setIsAddApplicationModalOpen(false);
            //     setIsCustomAppDialogOpen(true);
            // }}
            />}

            {/* {isCustomAppDialogOpen && <AddCustomApplicationDialog
                customApplications={customApplications}
                isOpen={isCustomAppDialogOpen}
                closeDialog={() => setIsCustomAppDialogOpen(false)}
                addCustomApp={addCustomApp}
                addAppAlreadyExists={addAppAlreadyExists}
            />} */}
        </div>
    )
}

export default GenAiMgmtOnboarding;