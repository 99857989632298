import {
    GridRowsProp,
    DataGrid,
    GridColDef,
    GridRowId,
    GridRowModesModel,
    GridRowModes,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowModel,
    GridRenderCellParams,
} from "@mui/x-data-grid";

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { graphql, createGraphQLClient } from '../../gql';
import { Box } from '@mui/material';
import { useState, useMemo } from 'react';
import { PopupMessageInput } from '../../components/PopupMessageInput';
import { PopupMessageActionVerification } from '../../components/PopupMessageActionVerification';
import { useNavigate, useParams } from "react-router-dom";
import { PSButton } from '../../ui-kit';
import { ApplicationTypes, PolicyTypes } from "../../gql/generated/graphql";
import { useGraphQL } from "../../hooks";
import { getConnectorByName } from "../../routes-new/Manage/ConnectorsCommon";
import * as React from "react";
import {GridActionsCellItemProps} from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { useCheckPermission } from "../../hooks/usePermissionCheck";

const ConnectorTypeToUrl: {
    [key in ApplicationTypes]: string
} = {
    REGULAR: 'homegrown-applications',
    EXTENSION: 'employees',
    CODE_ASSISTANT: 'developers',
    SHADOW: ''
}

const queryPolices = graphql(`
    query PoliciesByTypes($type: [String!] = ["REGULAR", "CODE_ASSISTANT", "EXTENSION"]) {
        policies(policyTypes: $type) {
            id
            name
            policyType
        }
    }   
`);

const queryPolicyContent = graphql(`
    query PoliciesContent($id: ID!) {
        policies(id: $id) {
            id
            name
            content
        }
    }   
`);

const mutationDeletePolicy = graphql(`
    mutation DeletePolicy($id: ID!) {
        deletePolicy(id: $id) {
            id
        }
    }
`);

const mutationDuplicatePolicy = graphql(`
    mutation DuplicatePolicy($id: ID!, $name: String!) {
        duplicatePolicy(id: $id, name: $name) {
            id
        }
    }
`);

const mutationUpdatePolicy = graphql(`
    mutation UpdatePolicy($id: ID!, $name: String) {
        updatePolicy(input: { id: $id, name: $name }) {
            id
        }
    }
`);

const savePolicyAsFile = (filename: string, data: string) => {
    const blob = new Blob([data], { type: "application/json" });
    const link = document.createElement("a");

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["application/json", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove();
};

type policyObject = {
    name: string;
    type: string;
}

type PoliciesViewerProps = {
    handlePopupMessage: (title: string, test: string) => void;
    policyType?: PolicyTypes;
    hide?: boolean
}

export const PolicyTypeMapperShort: { [key in PolicyTypes]: string } = {
    REGULAR: 'Homegrown Apps',
    CODE_ASSISTANT: 'Developers',
    EXTENSION: 'Employees',
}

export const PoliciesViewer = ({ handlePopupMessage, policyType, hide }: PoliciesViewerProps) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { applicationName, connectorName } = useParams();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [openPopupMessageInput, setOpenPopupMessageInput] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');
    const [duplicateNameValue, setDuplicateNameValue] = useState<string>('');
    const [policyId, setPolicyId] = useState<string | number>();
    const [openPopupMessageVerification, setOpenPopupMessageVerification] = useState<boolean>(false);

    const hasPermissionUpdatePolicy = useCheckPermission('ps.policy.update.policy');
    const hasPermissionDeletePolicy = useCheckPermission('ps.policy.delete.policy');
    const hasPermissionCreatePolicy = useCheckPermission('ps.policy.create.policy');
    
    const { data: policies } = useQuery(
        {
            queryKey: ["policies"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { policies } = await client.request(queryPolices, {
                    type: policyType ? [policyType] : ["REGULAR", "CODE_ASSISTANT", "EXTENSION"]
                });
                return policies
            }
        }
    );

    const deletePolicy = useMutation({
        mutationFn: async (variables: any) => {
            const client = createGraphQLClient();
            await client.request(mutationDeletePolicy, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['policies'] })
        },
    });

    const duplicatePolicy = useMutation({
        mutationFn: async (variables: any) => {
            const client = createGraphQLClient();
            await client.request(mutationDuplicatePolicy, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['policies'] })
        },
        onError: (error) => {
            let errorMessage = 'Failed to duplicate policy';
            if ((error as any).response.errors[0].message === 'Duplicate Name') {
                errorMessage = 'Duplicate policy name is not allowed';
            }
            handlePopupMessage('Duplicating Failed', errorMessage);
        },
    });

    const updatePolicy = useMutation({
        mutationFn: async (variables: any) => {
            const client = createGraphQLClient();
            await client.request(mutationUpdatePolicy, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['policies'] })
        },
        onError: (error) => {
            let errorMessage = 'Failed to duplicate policy';
            if ((error as any).response.errors[0].message === 'Duplicate Name') {
                errorMessage = 'Duplicate policy name is not allowed';
            }
            handlePopupMessage('Editing Failed', errorMessage);
        },
    });

    const connector = useGraphQL({
        document: getConnectorByName,
        variables: {
            name: applicationName! ?? connectorName!
        },
        select: data => data.applications[0]
    })

    const handlePopupMessageVerificationClose = () => {
        setPolicyId(undefined);
        setOpenPopupMessageVerification(false);
    }

    const handlePopupMessageVerificationYes = () => {
        deletePolicy.mutate({ id: policyId });
        setPolicyId(undefined);
        setOpenPopupMessageVerification(false);
    }


    const handleTextInputOnChange = (value: string) => {
        setDuplicateNameValue(value);
    }

    const handlePopupMessageInputClose = () => {
        setPolicyId(undefined);
        setDuplicateNameValue('');
        setOpenPopupMessageInput(false);
    }

    const handlePopupMessageInputSubmit = () => {
        duplicatePolicy.mutate({ id: policyId, name: duplicateNameValue });
        setPolicyId(undefined);
        setDuplicateNameValue('');
        setOpenPopupMessageInput(false);
    }

    const policiesById = useMemo(() => {
        let policiesById: Record<string, policyObject> = {};

        if (policies) {
            for (const policy of policies) {
                if (!(policy.id in policiesById)) {
                    policiesById[policy.id] = { name: policy.name, type: policy.policyType };
                }
            }
        }

        return policiesById;
    }, [policies]);

    const handleDeleteClick = (id: GridRowId) => () => {
        setPolicyId(id);
        setPopupTitle('Delete Policy');
        setPopupText(`You are about to delete the policy '${policiesById[id].name}'. Are you sure you want to delete it?`);
        setOpenPopupMessageVerification(true);
    };

    const handleDuplicateClick = (id: GridRowId) => () => {
        setPolicyId(id);
        setPopupTitle('Duplicate Policy');
        setPopupText('Insert a name for the new policy');
        setDuplicateNameValue(`copy of ${policiesById[id].name}`);
        setOpenPopupMessageInput(true);
    };

    const cleanPolicy = (policy: any): any => {
        let outputPolicy: any = {};
        for (const protection in policy) {
            const config = policy[protection];
            if (config.enabled) {
                delete config['category'];
                delete config['top10'];
                outputPolicy[protection] = config;
            }
        }
        return outputPolicy;
    }

    const handleDownloadClick = (id: GridRowId) => async () => {
        const client = createGraphQLClient();
        const { policies } = await client.request(queryPolicyContent, { id: id as string });
        let policy = policies[0].content;
        policy.prompt = cleanPolicy(policy.prompt);
        policy.response = cleanPolicy(policy.response);
        savePolicyAsFile(policies[0].name, JSON.stringify(policy))
    };

    const handleManagePolicyClick = (policyId: GridRowId, innerPolicyType: PolicyTypes) => () => {
        if (policyType) {
            navigate({
                pathname: `/${ConnectorTypeToUrl[connector.data!.applicationType!]}/manage/${applicationName ?? connectorName}/policies`,
                search: `?policyId=${policyId}`
            })
            return;
        }

        switch (innerPolicyType) {
            case PolicyTypes.Regular:
                navigate({
                    pathname: `/homegrown-applications/manage/default/policies`,
                    search: `?policyId=${policyId}`
                })
                break;
            case PolicyTypes.CodeAssistant:
                navigate({
                    pathname: `/developers/manage/default/policies`,
                    search: `?policyId=${policyId}`
                })
                break;
            case PolicyTypes.Extension:
                navigate({
                    pathname: `/employees/manage/default/policies`,
                    search: `?policyId=${policyId}`
                })
                break;
        }
    }

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
        const id = newRow.id;
        const name = newRow.name;

        if (name.trim() === '') {
            handlePopupMessage('Adding Failed', 'Empty name is not allowed');
            return oldRow;
        }

        if (newRow.name !== oldRow.name) {
            updatePolicy.mutate({ id, name });
        }

        return { id, name: policiesById[id].name } as GridRowModel;
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            minWidth: 220,
            flex: 0.33,
            editable: true,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    params.value
                )
            }
        },
        {
            field: "policyType",
            headerName: "Type",
            minWidth: 120,
            flex: 0.33,
            editable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span>
                        {PolicyTypeMapperShort[params.value as PolicyTypes]}
                    </span>
                )
            }
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            minWidth: 650,
            flex: 1,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
                const elements: React.ReactElement<GridActionsCellItemProps>[] = []
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <PSButton variant='square' iconName='PSSaveIcon' onClick={handleSaveClick(id)}>Save</PSButton>,
                        <PSButton variant='square' iconName='PSCancelIcon' onClick={handleCancelClick(id)}>Cancel</PSButton>
                    ];
                }
                elements.push(
                    <PSButton variant='square' iconName='PSSettingsIcon' onClick={handleManagePolicyClick(id, row.policyType)}>Manage Policy</PSButton>,
                )
                if(hasPermissionUpdatePolicy) {
                    elements.push(
                        <PSButton variant='square' iconName='PSEditIcon' onClick={handleEditClick(id)}>Rename</PSButton>,
                    )
                }
                if(hasPermissionDeletePolicy) {
                    elements.push(
                        <PSButton variant='square' iconName='PSCancelIcon' onClick={handleDeleteClick(id)}>Delete</PSButton>,
                    )
                }
                if(hasPermissionCreatePolicy) {
                    elements.push(
                        <PSButton variant='square' iconName='PSCopyIcon' onClick={handleDuplicateClick(id)}>Duplicate</PSButton>,
                    )
                }
                elements.push(
                    <PSButton variant='square' iconName='PSDownloadIcon' onClick={handleDownloadClick(id)}>Download</PSButton>,
                )
                return elements
            }
        }
    ];

    return (
        <Box>
            {
                policies &&
                <DataGrid
                    rows={policies as GridRowsProp}
                    columns={columns}
                    disableRowSelectionOnClick
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    hideFooter
                    autoHeight
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                />
            }
            <PopupMessageInput open={openPopupMessageInput} title={popupTitle} text={popupText} value={duplicateNameValue} handleTextInputOnChange={handleTextInputOnChange} handlePopupMessageClose={handlePopupMessageInputClose} handlePopupMessageSubmit={handlePopupMessageInputSubmit} />
            <PopupMessageActionVerification open={openPopupMessageVerification} title={popupTitle} text={popupText} handlePopupMessageClose={handlePopupMessageVerificationClose} handlePopupMessageYes={handlePopupMessageVerificationYes} />
        </Box>
    )
}