/** @jsxImportSource @emotion/react */
import { TextField } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { PSGenAIAddCard, PSButton, PSDialog, PSError, Text } from '../../ui-kit';
import { createGraphQLClient, graphql } from '../../gql';
import { useMutation, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { GetAllApplicationsQueryQuery, GenAiApplicationAdditionStatus, GenAiApplicationType, GenAiApplicationVerificationType } from '../../gql/generated/graphql';
import { AddVerifiedApplicationModalStyle } from './AddVerifiedApplicationModal.css';
import { useDebounce } from 'react-use';

const getAllApplications = graphql(`
    query GetAllApplicationsQuery($input: GetGenAiApplicationsInput!) {
        getGenAiApplications(input: $input) {
            genAiApplicationsWithAdditionStatus {
                additionStatus
                wasModified
                genAiApplication {
                    vendor
                    logo
                    isCustom
                    id
                    faviconUrl
                    name
                    domain
                    type
                }
            }
            securityApproach
            count
        }
    }
`);

const mutateCreateApplicationRule = graphql(`
    mutation CreateApplicationRule($input: [createGenAiApplicationRuleInput!]!) {
        createGenAiApplicationRule(input: $input)
    }
`)

type IProps = {
    isOpen: boolean;
    onClose: () => void;
    openCustomAppModal?: () => void;
    onApplicationActionAdd?: (selectedApplications: NonNullable<GetAllApplicationsQueryQuery['getGenAiApplications']['genAiApplicationsWithAdditionStatus']>) => void;
};

const AddVerifiedApplicationModal: React.FC<IProps> = (props) => {
    const { isOpen, onClose, openCustomAppModal, onApplicationActionAdd } = props;

    const queryClient = useQueryClient();
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
    const [, cancel] = useDebounce(() => {
        setDebouncedSearchValue(searchInputValue);
        return () => cancel();
    }, 200, [searchInputValue])
    const [selectedApplicationsId, setSelectedApplicationsId] = useState<Array<string>>([]);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const observerTarget = useRef<HTMLDivElement>(null);

    const { data, isLoading, isError, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
        queryKey: ['add-applications', debouncedSearchValue],
        queryFn: async ({ pageParam = 1 }) => {
            const client = createGraphQLClient();
            const { getGenAiApplications } = await client.request(getAllApplications, {
                input: {
                    Pagination: {
                        pageNumber: pageParam,
                        pageSize: 20
                    },
                    Filter: {
                        applicationType: [GenAiApplicationType.AiApplication, GenAiApplicationType.AiComponent],
                        verificationType: [GenAiApplicationVerificationType.PromptSecurityVerified],
                        searchQuery: debouncedSearchValue
                    }
                }
            });
            return getGenAiApplications;
        },
        getNextPageParam: (lastPage, pages) => {
            if (!lastPage || !lastPage.genAiApplicationsWithAdditionStatus || lastPage.genAiApplicationsWithAdditionStatus.length < 20) {
                return undefined;
            }
            return pages.length + 1;
        },
        initialPageParam: 1,
        refetchOnMount: true
    });

    useEffect(() => {
        setHasNextPage(!!data?.pages[data.pages.length - 1]?.genAiApplicationsWithAdditionStatus && data.pages[data.pages.length - 1]?.genAiApplicationsWithAdditionStatus?.length === 20);
    }, [data]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
                    fetchNextPage();
                }
            },
            { threshold: 1.0 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const { mutateAsync: createApplicationsRules } = useMutation({
        mutationFn: async () => {
            const client = createGraphQLClient();
            const { createGenAiApplicationRule } = await client.request(mutateCreateApplicationRule, {
                input: selectedApplicationsId.map(x => {
                    return {
                        id: x,
                    }
                })
            });

            return createGenAiApplicationRule;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appsNotToTrack'] });
            queryClient.invalidateQueries({ queryKey: ['getAllApplications'] });
        }
    })

    const selectApplication = (id: string) => {
        if (selectedApplicationsId.includes(id)) {
            setSelectedApplicationsId(selectedApplicationsId.filter(x => x !== id));
        } else {
            setSelectedApplicationsId([...selectedApplicationsId, id]);
        }
    }

    const handleOnApplicationActionAdd = () => {
        if (selectedApplicationsId.length === 0) {
            return;
        }
        const selectedApplications = data?.pages.flatMap(x => x.genAiApplicationsWithAdditionStatus).filter(x => selectedApplicationsId.includes(x?.genAiApplication?.id || ''));
        onApplicationActionAdd && onApplicationActionAdd(selectedApplications as NonNullable<GetAllApplicationsQueryQuery['getGenAiApplications']['genAiApplicationsWithAdditionStatus']>);
        onClose();
    }

    return (
        <PSDialog
            open={isOpen}
            onClose={onClose}
            action={onApplicationActionAdd ? handleOnApplicationActionAdd : createApplicationsRules}
            title='Add Verified Application'
            actionButtonText='Add'
            maxWidth='sm'
            PaperProps={{
                sx: {
                    height: 'calc(100vh - 100px)',
                }
            }}
        >
            <div css={AddVerifiedApplicationModalStyle.dialogContainer}>
                <div css={AddVerifiedApplicationModalStyle.searchContainer}>
                    <Text variant='bold'>Choose App </Text>
                    <TextField
                        autoFocus
                        variant='outlined'
                        sx={{ width: 'max(320px, 50%)' }}
                        size='small'
                        placeholder='Search'
                        value={searchInputValue}
                        onChange={(e) => setSearchInputValue(e.target.value)}
                    />
                </div>
                <div css={AddVerifiedApplicationModalStyle.applicationsContainer(Boolean(openCustomAppModal))}>
                    {isLoading && Array(10).fill(0).map((_, i) => {
                        return <PSGenAIAddCard key={i} isLoading />
                    })}

                    {isError && <PSError />}

                    {data?.pages.map((page, pageIndex) => (
                        <React.Fragment key={pageIndex}>
                            {page.genAiApplicationsWithAdditionStatus?.map(x => {
                                const { id, name, isCustom, domain, logo, faviconUrl, type } = x.genAiApplication;
                                return <PSGenAIAddCard
                                    key={id}
                                    applicationName={name}
                                    isAiComponent={type === GenAiApplicationType.AiComponent}
                                    isSelected={selectedApplicationsId.includes(id) || x.additionStatus === GenAiApplicationAdditionStatus.AddedAsException}
                                    isCustom={isCustom}
                                    isAppNotToTrack={x.additionStatus === GenAiApplicationAdditionStatus.AddedAsIgnored}
                                    isDisabled={x.additionStatus === GenAiApplicationAdditionStatus.AddedAsException || x.additionStatus === GenAiApplicationAdditionStatus.AddedAsIgnored}
                                    onClick={() => selectApplication(id)}
                                    imageUrl={isCustom ? `https://www.${domain}/favicon.ico` : (logo || faviconUrl || undefined)}
                                />
                            })}
                        </React.Fragment>
                    ))}

                    {isFetchingNextPage && Array(5).fill(0).map((_, i) => {
                        return <PSGenAIAddCard key={`loading-${i}`} isLoading />
                    })}

                    {data?.pages.flatMap(x => x.genAiApplicationsWithAdditionStatus).length === 0 && <Text>No applications found</Text>}

                    <div ref={observerTarget} style={{ height: 1 }} />
                </div>
                {(openCustomAppModal) && <div css={AddVerifiedApplicationModalStyle.addCustomApplicationContainer}>
                    <Text>Don't see your app? Add it manually</Text>
                    <PSButton placement='end' iconName='PSArrowRightIcon' variant='square' onClick={openCustomAppModal}>Add Custom App</PSButton>
                </div>}
            </div>
        </PSDialog>
    )
}

export default AddVerifiedApplicationModal;