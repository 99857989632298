/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {ColorThemeKeys} from "../../../../styles";

export const IconAndTextStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    iconWrapper: (size: number, iconColor?: ColorThemeKeys) => css`
        border-radius: 50%;
        border: 1px solid var(${iconColor ? `--color-${iconColor}` : '--color-black'});
        height: ${size}px;
        width: ${size}px;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};