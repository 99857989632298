/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text, Icon, PSButton, PSDialog } from '../../ui-kit';
import { GenAiMgmtGlobalSettingsStyle } from './GenAiMgmtGlobalSettings.css';
import GenAiMgmtRulesSettings from './GenAiMgmtRulesSettings';
import { css } from '@emotion/react';
import { Control, FieldValues, FormState, UseFormSetValue } from 'react-hook-form';
import { GetApplicationsGlobalSettingsQuery } from '../../gql/generated/graphql';
import AppsNotToTrack from './AppsNotToTrack';
import { useCheckPermission } from "../../hooks/usePermissionCheck";



type IProps = {
    onSaveClick: (shouldOverride: boolean) => void;
    onCancelClick: () => void;
    applicationFormState: FormState<FieldValues>;
    isSaveButtonLoading: boolean;
    globalSettings: NonNullable<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings']>;
    applicationControl: Control<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings'], any>;
    setApplicationFormValue: UseFormSetValue<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings']>;
};

const GenAiMgmtGlobalSettings: React.FC<IProps> = (props) => {
    const { applicationFormState, isSaveButtonLoading, globalSettings, applicationControl, onSaveClick, onCancelClick } = props;
    const hasPermission = useCheckPermission('ps.genAiApplications.update.globalSettings');

    const [isOverrideDialogOpen, setIsOverrideDialogOpen] = React.useState(false);

    return (
        <div css={GenAiMgmtGlobalSettingsStyle.self}>
            <div css={GenAiMgmtGlobalSettingsStyle.headerContainer}>
                <Text variant='header2'>Default settings</Text>
            </div>

            <React.Fragment>
                <div css={GenAiMgmtGlobalSettingsStyle.rulesContainer}>
                    <GenAiMgmtRulesSettings control={applicationControl} globalSettings={globalSettings} disabled={!hasPermission} />
                </div>

                <div css={GenAiMgmtGlobalSettingsStyle.appNotToTrackContainer}>
                    <div css={GenAiMgmtGlobalSettingsStyle.appsToNotTrackHeader}>
                        <Icon iconName='PSAppNotToTrackIcon' iconSize='medium' color='black-70' />
                        <Text variant='bold'>Apps NOT to track</Text>
                    </div>
                    <AppsNotToTrack applicationControl={applicationControl} globalSettings={globalSettings} setApplicationFormValue={props.setApplicationFormValue} disabled={!hasPermission} />
                </div>

                {
                    hasPermission &&
                        <div css={GenAiMgmtGlobalSettingsStyle.actionsContainer}>
                            <PSButton
                                onClick={onCancelClick}
                                css={css`margin-left: auto;`}
                                variant='flat'
                                disabled={!applicationFormState.isDirty}
                            >
                                Cancel
                            </PSButton>

                            <PSButton
                                disabled={!applicationFormState.isDirty}
                                variant='outlined'
                                onClick={() => setIsOverrideDialogOpen(true)}
                                isLoading={isSaveButtonLoading}
                            >
                                Save and override
                            </PSButton>

                            <PSButton
                                disabled={!applicationFormState.isDirty}
                                variant='filled'
                                onClick={() => onSaveClick(false)}
                                isLoading={isSaveButtonLoading}
                            >
                                Save default
                            </PSButton>
                        </div>
                }

                <PSDialog
                    title='Override modified settings'
                    open={isOverrideDialogOpen}
                    onClose={() => setIsOverrideDialogOpen(false)}
                    action={() => onSaveClick(true)}
                    actionButtonVariantType='critical'
                    actionButtonText='Override'
                >
                    <Text variant='bold'>You are about to override modified settings with default settings</Text>
                    <Text>This will delete all the existing settings and replace it with the Default settings</Text>
                </PSDialog>
            </React.Fragment>
        </div>
    )
}

export default GenAiMgmtGlobalSettings;