/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSIconWithPopperStyle = {
    self: css`
        padding-top: 10px;
    `,
    tooltipContainer: css`
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    `,
    tooltipSection: css`
        display: flex;
        flex-direction: column;
    `,
};