/** @jsxImportSource @emotion/react */
import React, { useMemo, useRef, useState, useLayoutEffect } from 'react';
import { PSPercentageBarStyle } from './PSPercentageBar.css';
import Text from '../../Text/Text';
import { ColorThemeKeys } from '../../../styles';
import { debounce } from 'lodash';
import Tooltip from "../../Tooltip/Tooltip";

export type BarData = {
    label: string;
    value: number;
};

type IProps = {
    bars: BarData[];
    barCollapseThresholdPx?: number;
    barColor?: ColorThemeKeys;
    debounceTimeMs?: number;
};

const GAP_WIDTH = 2;

const PSPercentageBar: React.FC<IProps> = (props) => {
    const { bars, barCollapseThresholdPx = 100, barColor = 'black', debounceTimeMs = 50 } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [actualBarWidths, setActualBarWidths] = useState<{ [key: string]: number }>({});

    const debouncedHandleResize = useMemo(
        () =>
            debounce(() => {
                if (containerRef.current) {
                    setContainerWidth(containerRef.current.offsetWidth);
                }
            }, debounceTimeMs),
        []
    );

    useLayoutEffect(() => {
        debouncedHandleResize();

        const resizeObserver = new ResizeObserver(debouncedHandleResize);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useLayoutEffect(() => {
        const measureBars = () => {
            const bars = containerRef.current?.querySelectorAll('.single-bar');
            if (!bars) return;

            const widths: { [key: string]: number } = {};
            bars.forEach((bar, index) => {
                const width = Math.ceil(bar.getBoundingClientRect().width);
                if (props.bars[index]) {
                    widths[props.bars[index].label] = width;
                }
            });
            setActualBarWidths(widths);
        };

        measureBars();

        const debouncedMeasure = debounce(measureBars, 0);
        const resizeObserver = new ResizeObserver(debouncedMeasure);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [bars, containerWidth]);

    const { visibleBars, hiddenBars } = useMemo(() => {
        const total = bars.reduce((sum, bar) => sum + bar.value, 0);
        let processedBars = bars.map((bar) => ({
            ...bar,
            id: bar.label,
            percentage: total > 0 ? Math.round((bar.value / total) * 100) : 0,
        }));

        if (processedBars.length <= 2) {
            return {
                visibleBars: processedBars,
                hiddenBars: []
            };
        }

        processedBars.sort((a, b) => b.percentage - a.percentage);

        const visibleBars: typeof processedBars = [];
        const hiddenBars: typeof processedBars = [];

        let remainingWidth = containerWidth;
        let remainingBars = [...processedBars];

        while (remainingBars.length > 0) {
            const currentBar = remainingBars[0];
            const gapsNeeded = visibleBars.length;
            const availableWidthForBar = remainingWidth - (gapsNeeded * GAP_WIDTH);
            const barWidthInPixels = (currentBar.percentage * availableWidthForBar) / 100;

            if (barWidthInPixels >= barCollapseThresholdPx || visibleBars.length === 0) {
                visibleBars.push(currentBar);
                remainingBars = remainingBars.slice(1);
            } else {
                hiddenBars.push(...remainingBars);
                break;
            }
        }

        if (hiddenBars.length > 0) {
            const hiddenTotal = hiddenBars.reduce((sum, bar) => sum + bar.value, 0);
            const aggregatedHiddenBar = {
                label: `+${hiddenBars.length}`,
                value: hiddenTotal,
                id: 'hidden_group',
                percentage: Math.round((hiddenTotal / total) * 100)
            };
            visibleBars.push(aggregatedHiddenBar);
        }

        if (visibleBars.length > 0) {
            const visibleTotal = visibleBars.reduce((sum, bar) => sum + bar.value, 0);
            visibleBars.forEach(bar => {
                bar.percentage = Math.round((bar.value / visibleTotal) * 100);
            });

            const totalPercentage = visibleBars.reduce((sum, bar) => sum + bar.percentage, 0);
            if (totalPercentage !== 100 && visibleBars.length > 0) {
                const diff = 100 - totalPercentage;
                visibleBars[0].percentage += diff;
            }
        }

        return {
            visibleBars,
            hiddenBars
        };
    }, [bars, barCollapseThresholdPx, containerWidth, actualBarWidths]);

    return (
        <div css={PSPercentageBarStyle.barContainer(GAP_WIDTH)} ref={containerRef}>
            {visibleBars.map((bar) => {
                const isHiddenGroup = bar.id === 'hidden_group';
                const hiddenBarTooltipText = hiddenBars?.map((bar) => bar?.label)?.join(', ');

                const Bar = (
                    <div
                        key={bar.label}
                        css={PSPercentageBarStyle.singleBarContainer(bar.percentage)}
                    >
                        <Text textCss={PSPercentageBarStyle.singleBarLabel} variant='small' ellipsis color='black-70'>{bar.label}</Text>
                        <div css={PSPercentageBarStyle.singleBar(barColor)} className='single-bar'></div>
                    </div>
                );

                return (
                    !isHiddenGroup ? Bar :
                    <Tooltip title={hiddenBarTooltipText} placement='top' key={bar.label}>
                        {Bar}
                    </Tooltip>
                )
            })}
        </div>
    );
};

export default PSPercentageBar;