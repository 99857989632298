import { Control, UseFormGetValues } from "react-hook-form";
import { EnsureKey } from "../../../../utils/typescriptUtilityTypes";
import { LLMsTranslations } from "./protections_defaults";
import {
  DATA_PRIVACY_GUIDELINES_PRESETS,
  HARMFUL_CONTENT_MODERATOR_CATEGORIES,
  LANGUAGES,
  MODELS,
  MODERATION_TYPES,
  MODERATOR_CATEGORIES,
  PROTECTION_ACTIONS,
  REDACT_MODES,
  SECRET_PLUGINS,
  SENSITIVE_DATA_TYPES,
  SUPPORTED_CODE_LANGUAGES,
  TDataPrivacyGuidelinesPresetKeys,
  TOKEN_LIMITATION_ENCODING_NAMES,
  TOPICS_DETECTOR_TOPICS,
  VENDORS
} from "./protections_static_values";

export type TProtectionTextType = "prompt" | "response";
export type TProtectionCategories = "data privacy" | "safety" | "general" | "security";

export type TVendors = ({
  vendor: EnsureKey<typeof VENDORS>['azure_openai']
  llm_api_key: string;
  azure_endpoint: string;
  model: keyof typeof MODELS;
} | {
  vendor: EnsureKey<typeof VENDORS>['openai'];
  llm_api_key: string;
  model: keyof typeof MODELS;
} | {
  vendor: EnsureKey<typeof VENDORS>['local'];
})

export type TProtection<T extends TProtectionCategories> = {
  enabled: boolean;
  category: T;
  top10?: Array<Uppercase<keyof typeof LLMsTranslations>>;
};

//--------------REGEX--------------//
const REGEX_KEY = "Regex" as const;
export type TRegexProtection = TProtection<"data privacy"> & {
  redact: boolean;
  redact_text: string;
  bad_patterns: Array<string>;
  good_patterns: Array<string>;
};

//--------------MODERATOR--------------//
const MODERATOR_KEY = "Moderator" as const;
export type TModeratorProtection = TProtection<"safety"> & {
  threshold: number; // 0 to 1.00 - jumps of 0.05;
  categories: Partial<Array<keyof typeof MODERATOR_CATEGORIES>>;
};

//--------------HARMFUL_CONTENT_MODERATOR--------------//
const HARMFUL_CONTENT_MODERATOR_KEY = "Harmful Content Moderator" as const;
export type THarmfulContentModeratorProtection = TProtection<"safety"> & {
  threshold_mode: string;
  categories: Partial<Array<keyof typeof HARMFUL_CONTENT_MODERATOR_CATEGORIES>>;
};

//--------------SENTIMENT--------------//
const SENTIMENT_KEY = "Sentiment" as const;
export type TSentimentProtection = TProtection<"safety"> & {
  threshold: number; // -1.00 to 1.00 - jumps of 0.1;
};

//--------------CODE_DETECTOR--------------//
const CODE_DETECTOR_KEY = "Code Detector" as const;
export type TCodeDetectorProtection = TProtection<"data privacy"> & {
  threshold: number; // 0 to 1.00 - jumps of 0.05;
  denied: Partial<Array<keyof typeof SUPPORTED_CODE_LANGUAGES>>;
  allowed: Partial<Array<keyof typeof SUPPORTED_CODE_LANGUAGES>>;
  indented: boolean;
};

//--------------STRICT_FORMAT--------------//
const STRICT_FORMAT_KEY = "Strict Format" as const;
export type TStrictFormatProtection = TProtection<"security"> & {
  required_format: "json";
  required_elements: number; //0 - 10 round number only
  require_whole_text: boolean;
};

//--------------URLs_DETECTOR--------------//
const URLS_DETECTOR_KEY = "URLs Detector" as const;
export type TUrlsDetectorProtection = TProtection<"general"> & {
  only_allowed: boolean;
  denied_domains: Array<string>;
  allowed_domains: Array<string>;
};

//--------------SENSITIVE_DATA--------------//
const SENSITIVE_DATA_KEY = "Sensitive Data" as const;
export type TSensitiveDataProtection = TProtection<"data privacy"> & {
  action: keyof typeof PROTECTION_ACTIONS;
  use_faker: boolean;
  thresholds: Partial<Record<keyof typeof SENSITIVE_DATA_TYPES & 'ALL' | string, number>>; // number 0 to 1.00 - jumps of 0.05;
  hidden_names: Array<string>;
  allowed_names: Array<string>;
  entity_types: Partial<Array<keyof typeof SENSITIVE_DATA_TYPES>> | Array<string>;
  custom_entity_types: Array<string>;
};

//--------------SANITIZER_MODEL--------------//
const SANITIZER_MODEL_KEY = "Sanitizer Model" as const;
export type TSanitizerModelProtection = TProtection<"data privacy"> & {
  action: EnsureKey<typeof PROTECTION_ACTIONS>['sanitize'];
  use_faker: false;
} & TVendors;

//--------------TOPICS_DETECTOR--------------//
const TOPICS_DETECTOR_KEY = "Topics Detector" as const;
export type TTopicsDetectorProtection = TProtection<"safety"> & {
  threshold: number; // 0 to 1.00 - jumps of 0.05;
  filter_type: keyof typeof MODERATION_TYPES;
  Topics: Partial<Array<keyof typeof TOPICS_DETECTOR_TOPICS>> | Array<string>;
  custom_topics: Array<string>;
  filter: Array<string>;
};

//--------------GITLEAKS_SECRETS--------------//
const SECRETS_KEY = "Secrets" as const;
export type TSecretsProtection = TProtection<"data privacy"> & {
  redact_mode: keyof typeof REDACT_MODES;
  ignored_rules: Partial<Array<keyof typeof SECRET_PLUGINS>>;
};

//--------------PROMPT_HARDENING--------------//
const PROMPT_HARDENING_KEY = "Prompt Hardening" as const;
export type TPromptHardeningProtection = TProtection<"security"> & {
  xml_tagging: boolean;
  seq_enclosure: boolean;
  add_canary_word: false;
};

//--------------TOKEN_LIMITATION--------------//
const TOKEN_LIMITATION_KEY = "Token Limitation" as const;
export type TTokenLimitationProtection = TProtection<"general"> & {
  limit: number; // 0 to 32678; step 8; can be between 8 steps - STRICT
  model_name: null;
  encoding_name: keyof typeof TOKEN_LIMITATION_ENCODING_NAMES;
};

//--------------TOKEN_RATE_LIMIT--------------//
const TOKEN_RATE_LIMIT_KEY = "Token Rate Limit" as const;
export type TTokenRateLimitProtection = TProtection<"security"> & {
  global_limit_per_minute: number; // 0 to 32678; step 8; can be between 8 steps - STRICT
  identity_limit_per_minute: number; // 0 to 32678; step 8; can be between 8 steps - STRICT
  encoding_name: keyof typeof TOKEN_LIMITATION_ENCODING_NAMES;
};

//--------------LANGUAGE_DETECTOR--------------//
const LANGUAGE_DETECTOR_KEY = "Language Detector" as const;
export type TLanguageDetectorProtection = TProtection<"safety"> & {
  denied: Partial<Array<keyof typeof LANGUAGES>>;
  allowed: Partial<Array<keyof typeof LANGUAGES>>;
};

//--------------JAILBREAK_DETECTOR--------------//
const JAILBREAK_DETECTOR_KEY = "Jailbreak Detector" as const;
export type TJailbreakDetectorProtection = TProtection<"security"> & TVendors;

//--------------PROMPT_INJECTION_ENGINE--------------//
const PROMPT_INJECTION_ENGINE_KEY = "Prompt Injection Engine" as const;
export type TPromptInjectionEngineProtection = TProtection<"security"> & {
  "Prompt Injection Classifier": {
    enabled: boolean;
    threshold: number; // 0 to 1.00 - jumps of 0.01;
  };
  "Prompt Injection LLM Judger": {
    enabled: boolean;
    threshold: number; // 0 to 1.00 - jumps of 0.05;
  } & TVendors;
  "Prompt Injection Similarity": {
    top_k: number; // 0 to 30; step 1;
    enabled: boolean;
    threshold: number; // 0 to 1.00 - jumps of 0.01;
  };
};

//--------------DATA_PRIVACY_GUIDELINES--------------//
const DATA_PRIVACY_GUIDELINES_KEY = "Data Privacy Guidelines" as const;
export type TDataPrivacyGuidelinesProtection = TProtection<"safety"> & {
  threshold: number; // 0 to 1.00 - jumps of 0.05;
  prefix_instruct: string;
  suffix_instruct: string;
  judger_prompt: string;
  guidelines: (typeof DATA_PRIVACY_GUIDELINES_PRESETS)[TDataPrivacyGuidelinesPresetKeys];
  custom_guidelines: Array<string>;
  industry: TDataPrivacyGuidelinesPresetKeys;
} & TVendors;

//--------------PROMPT_LEAK_DETECTOR--------------//
const PROMPT_LEAK_DETECTOR_KEY = "Prompt Leak Detector" as const;
export type TPromptLeakDetectorProtection = TProtection<"security"> & {
  threshold: number; // 0 to 1.00 - jumps of 0.05;
  chunksize: number; // 10 - 200;
};

//--------------CUSTOM_MODERATOR--------------//
const CUSTOM_MODERATOR_KEY = "Custom Moderator" as const;
export type TCustomModeratorProtection = TProtection<"safety"> & {
  threshold: number; // 0 to 1.00 - jumps of 0.05;
  judger_prompt: string;
} & TVendors;

//--------------!LEGACY! DESANITATION--------------//
// const DESANITATION_KEY = "Desanitation" as const;
// export type TDesanitationProtection = TProtection<"data privacy">;

export type TAllProtections =
  TRegexProtection
  | TSecretsProtection
  | TModeratorProtection
  | THarmfulContentModeratorProtection
  | TSentimentProtection
  | TCodeDetectorProtection
  | TStrictFormatProtection
  | TUrlsDetectorProtection
  | TSensitiveDataProtection
  | TSanitizerModelProtection
  | TTopicsDetectorProtection
  | TPromptHardeningProtection
  | TTokenLimitationProtection
  | TTokenRateLimitProtection
  | TLanguageDetectorProtection
  | TJailbreakDetectorProtection
  | TPromptInjectionEngineProtection
  | TDataPrivacyGuidelinesProtection
  | TPromptLeakDetectorProtection
  | TCustomModeratorProtection;

export const AllProtectionKeys = [
  REGEX_KEY,
  SECRETS_KEY,
  MODERATOR_KEY,
  SENTIMENT_KEY,
  CODE_DETECTOR_KEY,
  STRICT_FORMAT_KEY,
  URLS_DETECTOR_KEY,
  SENSITIVE_DATA_KEY,
  SANITIZER_MODEL_KEY,
  TOPICS_DETECTOR_KEY,
  PROMPT_HARDENING_KEY,
  TOKEN_LIMITATION_KEY,
  TOKEN_RATE_LIMIT_KEY,
  LANGUAGE_DETECTOR_KEY,
  JAILBREAK_DETECTOR_KEY,
  PROMPT_INJECTION_ENGINE_KEY,
  DATA_PRIVACY_GUIDELINES_KEY,
  PROMPT_LEAK_DETECTOR_KEY,
  CUSTOM_MODERATOR_KEY,
  HARMFUL_CONTENT_MODERATOR_KEY
];

type HGAPromptProtections = {
  [DATA_PRIVACY_GUIDELINES_KEY]: TDataPrivacyGuidelinesProtection;
  [JAILBREAK_DETECTOR_KEY]: TJailbreakDetectorProtection;
  [PROMPT_HARDENING_KEY]: TPromptHardeningProtection;
  [PROMPT_INJECTION_ENGINE_KEY]: TPromptInjectionEngineProtection;
  [STRICT_FORMAT_KEY]: TStrictFormatProtection;
  [SANITIZER_MODEL_KEY]: TSanitizerModelProtection;
  [SENSITIVE_DATA_KEY]: TSensitiveDataProtection;
  [REGEX_KEY]: TRegexProtection;
  [SECRETS_KEY]: TSecretsProtection;
  [TOKEN_LIMITATION_KEY]: TTokenLimitationProtection;
  [TOKEN_RATE_LIMIT_KEY]: TTokenRateLimitProtection;
  [CODE_DETECTOR_KEY]: TCodeDetectorProtection;
  [LANGUAGE_DETECTOR_KEY]: TLanguageDetectorProtection;
  [URLS_DETECTOR_KEY]: TUrlsDetectorProtection;
  [TOPICS_DETECTOR_KEY]: TTopicsDetectorProtection;
  [MODERATOR_KEY]: TModeratorProtection;
  [SENTIMENT_KEY]: TSentimentProtection;
  [CUSTOM_MODERATOR_KEY]: TCustomModeratorProtection;
  [HARMFUL_CONTENT_MODERATOR_KEY]: THarmfulContentModeratorProtection;
}

type HGAResponseProtections = {
  [PROMPT_LEAK_DETECTOR_KEY]: TPromptLeakDetectorProtection;
  [STRICT_FORMAT_KEY]: TStrictFormatProtection;
  [SANITIZER_MODEL_KEY]: TSanitizerModelProtection;
  [SENSITIVE_DATA_KEY]: TSensitiveDataProtection;
  [REGEX_KEY]: TRegexProtection;
  [SECRETS_KEY]: TSecretsProtection;
  [URLS_DETECTOR_KEY]: TUrlsDetectorProtection;
  [CODE_DETECTOR_KEY]: TCodeDetectorProtection;
  [LANGUAGE_DETECTOR_KEY]: TLanguageDetectorProtection; // & { same_language: boolean; };
  [TOKEN_LIMITATION_KEY]: TTokenLimitationProtection;
  [TOPICS_DETECTOR_KEY]: TTopicsDetectorProtection;
  [MODERATOR_KEY]: TModeratorProtection;
  [SENTIMENT_KEY]: TSentimentProtection;
  [CUSTOM_MODERATOR_KEY]: TCustomModeratorProtection;
  [HARMFUL_CONTENT_MODERATOR_KEY]: THarmfulContentModeratorProtection;
}

export type THomeGrownApplications = {
  prompt: Partial<HGAPromptProtections>;
  response: Partial<HGAResponseProtections>;
};

const HGAPromptProtectionObj: Record<keyof HGAPromptProtections, boolean> = {
  [DATA_PRIVACY_GUIDELINES_KEY]: true,
  [JAILBREAK_DETECTOR_KEY]: true,
  [PROMPT_HARDENING_KEY]: true,
  [PROMPT_INJECTION_ENGINE_KEY]: true,
  [STRICT_FORMAT_KEY]: true,
  [SANITIZER_MODEL_KEY]: true,
  [SENSITIVE_DATA_KEY]: true,
  [REGEX_KEY]: true,
  [SECRETS_KEY]: true,
  [TOKEN_LIMITATION_KEY]: true,
  [TOKEN_RATE_LIMIT_KEY]: true,
  [CODE_DETECTOR_KEY]: true,
  [LANGUAGE_DETECTOR_KEY]: true,
  [URLS_DETECTOR_KEY]: true,
  [TOPICS_DETECTOR_KEY]: true,
  [MODERATOR_KEY]: true,
  [SENTIMENT_KEY]: true,
  [CUSTOM_MODERATOR_KEY]: true,
  [HARMFUL_CONTENT_MODERATOR_KEY]: true,
}
export const HGAPromptProtectionKeys = Object.keys(HGAPromptProtectionObj)

const HGAResponseProtectionObj: Record<keyof HGAResponseProtections, boolean> = {
  [PROMPT_LEAK_DETECTOR_KEY]: true,
  [STRICT_FORMAT_KEY]: true,
  [SANITIZER_MODEL_KEY]: true,
  [SENSITIVE_DATA_KEY]: true,
  [REGEX_KEY]: true,
  [SECRETS_KEY]: true,
  [URLS_DETECTOR_KEY]: true,
  [CODE_DETECTOR_KEY]: true,
  [LANGUAGE_DETECTOR_KEY]: true,
  [TOKEN_LIMITATION_KEY]: true,
  [TOPICS_DETECTOR_KEY]: true,
  [MODERATOR_KEY]: true,
  [SENTIMENT_KEY]: true,
  [CUSTOM_MODERATOR_KEY]: true,
  [HARMFUL_CONTENT_MODERATOR_KEY]: true,
}
export const HGAResponseProtectionKeys = Object.keys(HGAResponseProtectionObj)

export type TEmployees = {
  prompt: Partial<{
    [DATA_PRIVACY_GUIDELINES_KEY]: TDataPrivacyGuidelinesProtection;
    [SANITIZER_MODEL_KEY]: TSanitizerModelProtection;
    [SENSITIVE_DATA_KEY]: TSensitiveDataProtection;
    [REGEX_KEY]: TRegexProtection;
    [SECRETS_KEY]: TSecretsProtection;
    [TOKEN_LIMITATION_KEY]: TTokenLimitationProtection;
    [TOKEN_RATE_LIMIT_KEY]: TTokenRateLimitProtection;
    [CODE_DETECTOR_KEY]: TCodeDetectorProtection;
    [LANGUAGE_DETECTOR_KEY]: TLanguageDetectorProtection;
    [URLS_DETECTOR_KEY]: TUrlsDetectorProtection;
    [MODERATOR_KEY]: TModeratorProtection;
    [TOPICS_DETECTOR_KEY]: TTopicsDetectorProtection;
    [SENTIMENT_KEY]: TSentimentProtection;
    [CUSTOM_MODERATOR_KEY]: TCustomModeratorProtection;
    [HARMFUL_CONTENT_MODERATOR_KEY]: THarmfulContentModeratorProtection;
  }>;
};

export type TDevelopers = {
  prompt: Partial<{
    [DATA_PRIVACY_GUIDELINES_KEY]: TDataPrivacyGuidelinesProtection;
    [SANITIZER_MODEL_KEY]: TSanitizerModelProtection;
    [SENSITIVE_DATA_KEY]: TSensitiveDataProtection;
    [REGEX_KEY]: TRegexProtection;
    [SECRETS_KEY]: TSecretsProtection;
    [TOKEN_LIMITATION_KEY]: TTokenLimitationProtection;
  }>;
  response: Partial<{
    [TOKEN_LIMITATION_KEY]: TTokenLimitationProtection;
  }>;
};

export type TConnectorsTypes = THomeGrownApplications | TEmployees | TDevelopers;

export type TConnectorsNames = 'developers' | 'homegrown' | 'employees';

export type IProtectionFormProps<TProtection extends TAllProtections> = {
  protection: TProtection;
  control: Control<any>;
  formName: string;
  isAdvancedMode?: boolean;
};

export type IProtectionProps = {
  control: Control<any>;
  getValues: UseFormGetValues<{
    content: TConnectorsTypes | any;
  }>,
  isAdvancedMode?: boolean;
  connectorType: TConnectorsNames
};