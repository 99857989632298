/** @jsxImportSource @emotion/react */
import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { PSMenuItem } from '../../../../ui-kit';
import { IProtectionFormProps, TJailbreakDetectorProtection, VENDORS } from '../Common';
import { JailbreakDetectorFormStyle } from './JailbreakDetectorForm.css';


const JailbreakDetectorForm: React.FC<IProtectionFormProps<TJailbreakDetectorProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;

    if (!isAdvancedMode) return null;

    return (
        <div css={JailbreakDetectorFormStyle.self}>
            <Controller
                name={`${formName}.vendor`}
                control={control}
                defaultValue={protection.vendor}
                render={({ field }) => (
                    <FormControl>
                        <InputLabel id="label">Vendor</InputLabel>
                        <Select
                            {...field}
                            labelId="label"
                            size='small'
                            label="Vendor"
                        >
                            {Object.entries(VENDORS).map(([key, value]) => (
                                <PSMenuItem key={key} value={key} >{value}</PSMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />



            {protection.vendor === 'openai' && (
                <React.Fragment>
                    <Controller
                        name={`${formName}.model`}
                        defaultValue={protection.model}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="Model"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        name={`${formName}.llm_api_key`}
                        defaultValue={protection.llm_api_key}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="API Key"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </React.Fragment>
            )}

            {protection.vendor === 'azure_openai' && (
                <React.Fragment>
                    <Controller
                        name={`${formName}.model`}
                        defaultValue={protection.model}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="Model"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        name={`${formName}.llm_api_key`}
                        defaultValue={protection.llm_api_key}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="API Key"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        name={`${formName}.azure_endpoint`}
                        defaultValue={protection.azure_endpoint}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="Azure Endpoint"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </React.Fragment>
            )}
        </div>
    )
}

export default JailbreakDetectorForm;