

import { Box, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { createGraphQLClient, graphql } from '../../gql';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { PolicyTypes } from '../../gql/generated/graphql';
import { PSButton, Text } from '../../ui-kit';
import { useCheckPermission } from "../../hooks/usePermissionCheck";

const mutationCreatePolicy = graphql(`
    mutation CreatePolicy($name: String!, $policyType: String!) {
        createPolicy(input: { name: $name, policyType: $policyType }) {
            id
        }
    }
`);

type PolicyAddProps = {
    handlePopupMessage: (title: string, test: string) => void
    policyType?: PolicyTypes;
}

export const PolicyTypeMapper: { [key in PolicyTypes]: string } = {
    REGULAR: 'Homegrown Apps Policy',
    CODE_ASSISTANT: 'Developers Policy',
    EXTENSION: 'Employees Policy',
}

export const PolicyAdd = ({ handlePopupMessage, policyType }: PolicyAddProps) => {
    const queryClient = useQueryClient();

    const [name, setName] = useState("");
    const [selectedPolicyType, setSelectedPolicyType] = useState<string>((policyType ?? PolicyTypes.Regular) as string);
    const hasPermissionCreatePolicy = useCheckPermission('ps.policy.create.policy');


    const createPolicy = useMutation({
        mutationFn: async (variables: any) => {
            const client = createGraphQLClient();
            const { createPolicy } = await client.request(mutationCreatePolicy, variables);
            return createPolicy;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['policies'] })
        },
        onError: (error) => {
            let errorMessage = 'Failed to duplicate policy';
            if ((error as any).response.errors[0].message === 'Duplicate Name') {
                errorMessage = 'Duplicate policy name is not allowed';
            }
            handlePopupMessage('Adding Failed', errorMessage);
        },
    });

    const nameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    };

    const policyTypeChange = (event: SelectChangeEvent) => {
        setSelectedPolicyType(event.target.value as string)
    };

    const savePolicy = () => {
        if (name.trim() === '') {
            handlePopupMessage('Adding Failed', 'Empty name is not allowed');
            setName("");
            return;
        }
        createPolicy.mutate({ name, policyType: selectedPolicyType });
        setName("");
        setSelectedPolicyType(PolicyTypes.Regular as string);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Text variant='bold'>Add Policy</Text>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <TextField
                    type="text"
                    label="name"
                    variant="outlined"
                    size='small'
                    value={name}
                    disabled={!hasPermissionCreatePolicy}
                    onChange={nameChange}
                />
                {!policyType && <Select
                    value={selectedPolicyType}
                    onChange={policyTypeChange}
                    size='small'
                >
                    {
                        Object.values(PolicyTypes).map((value, i) =>
                            <MenuItem key={i} value={value}>{PolicyTypeMapper[value]}</MenuItem>
                        )
                    }
                </Select>}
                { hasPermissionCreatePolicy && <PSButton variant="filled" onClick={savePolicy}>Add</PSButton> }
            </Box>
        </Box>
    )

}