/** @jsxImportSource @emotion/react */
import { FC, ReactNode } from "react";
import { SingleSideStyle } from './SingleSide.css';
import {
    Text,
    Icon,
    PSPopper,
} from "../index";
import { IconNames } from "../Icon/Icon";
import { formatNumberWithCommasAndMaxValue } from "../../utils";
import { ColorThemeKeys } from '../../styles';
import { Skeleton } from "@mui/material";
import { SerializedStyles } from "@emotion/react";
import useBreakpoints from "../../hooks/useBreakpoints";

type BaseProps = {
    topText?: string;
    topTextColor?: ColorThemeKeys;
    bottomText: string;
    bottomTextColor?: ColorThemeKeys;
    color?: ColorThemeKeys;
    numberContent: number | ReactNode;
    skeletonColor?: ColorThemeKeys;
    isLoading?: boolean;
    additionalNumberCss?: SerializedStyles;
    tooltipText?: string
    numberFormater?: (arg: number) => string
};

type TPropsForPSIcons = {
    iconName: IconNames;
    customIcon?: never;
}

type TPropsForCustomIcons = {
    iconName?: never;
    customIcon: ReactNode;
}

export type IProps = BaseProps & (TPropsForPSIcons | TPropsForCustomIcons)

const SingleSide: FC<IProps> = (props) => {
    const {topText, topTextColor, bottomText, bottomTextColor, numberContent, iconName, customIcon, color, isLoading, additionalNumberCss, tooltipText, numberFormater, skeletonColor = 'black-30'} = props;

    const {screenWidth} = useBreakpoints();
    const shouldReduceNumberFontSize = screenWidth < 1600;

    if (isLoading) return (
        <div css={SingleSideStyle.skeletonContainer}>
            <Skeleton width={70} height={20} css={SingleSideStyle.skeleton(skeletonColor)}/>
            <Skeleton width={70} height={20} css={SingleSideStyle.skeleton(skeletonColor)}/>
            <Skeleton width={70} height={20} css={SingleSideStyle.skeleton(skeletonColor)}/>
        </div>
    )

    const IconMarkup = customIcon || <Icon iconSize={35} iconName={iconName}/>;

    const formatter = numberFormater || formatNumberWithCommasAndMaxValue;

    const Markup =  (
        <div css={SingleSideStyle.self}>
            {topText && IconMarkup}
            <div css={SingleSideStyle.dataContainer}>

                <div css={SingleSideStyle.topContainer(!!topText)}>
                    {topText ? <Text variant='bold' color={topTextColor || color} ellipsis>{topText}</Text> : IconMarkup}
                </div>

                <div css={SingleSideStyle.numberContainer(!topText)}>
                    {typeof numberContent === 'number' ? <Text color={color} css={[SingleSideStyle.numberContent(shouldReduceNumberFontSize), additionalNumberCss]}>{formatter(numberContent)}</Text> : numberContent}
                </div>

                <div css={SingleSideStyle.bottomContainer(!topText)}>
                    {bottomText && <Text variant='text' color={bottomTextColor || color} ellipsis><span>{bottomText}</span></Text>}
                </div>

            </div>
        </div>
    )

    if (!tooltipText) return Markup

    return (
        <PSPopper tooltip={tooltipText}>{Markup}</PSPopper>
    )
}

export default SingleSide;