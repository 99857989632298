import { css } from "@emotion/react";
import { TButtonSize, TFlatButtonVariantType } from "./PSButton";

const BUTTON_CONFIG = {
    sizes: {
        small: '5px 15px',
        medium: '10px 15px',
    },
    variants: {
        positive: {
            default: {
                background: 'var(--color-transparent)',
                color: 'var(--color-purple-80)',
                iconColor: 'var(--color-purple-80)',
                borderColor: 'var(--color-transparent)',
            },
            hover: {
                color: 'var(--color-purple-80)',
                iconColor: 'var(--color-purple-80)',
                background: 'var(--color-blue-5)',
            },
            pressed: {
                color: 'var(--color-purple-80)',
                iconColor: 'var(--color-purple-80)',
                background: 'var(--color-blue-5)',
                borderColor: 'var(--color-blue-20)',
            },
            disabled: {
                color: 'var(--color-black-30)',
                iconColor: 'var(--color-black-30)',
            },
        },
        negative: {
            default: {
                background: 'transparent',
                color: 'var(--color-red-50)',
                iconColor: 'var(--color-red-50)',
                borderColor: 'var(--color-transparent)',
            },
            hover: {
                color: 'var(--color-white)',
                iconColor: 'var(--color-white)',
                background: 'var(--color-blue-80)',
            },
            pressed: {
                color: 'var(--color-white)',
                iconColor: 'var(--color-white)',
                background: 'var(--color-red-80)',
                borderColor: 'var(--color-red-30)',
            },
            disabled: {
                color: 'var(--color-black-30)',
                iconColor: 'var(--color-black-30)',
            },
        },
        secondary: {
            default: {
                background: 'transparent',
                color: 'var(--color-blue-80)',
                iconColor: 'var(--color-blue-80)',
                borderColor: 'var(--color-transparent)',
            },
            hover: {
                color: 'var(--color-blue-80)',
                iconColor: 'var(--color-blue-80)',
                background: 'var(--color-black-20)',
            },
            pressed: {
                color: 'var(--color-blue-80)',
                iconColor: 'var(--color-blue-80)',
                background: 'var(--color-black-20)',
                borderColor: 'var(--color-black-20)',
            },
            disabled: {
                color: 'var(--color-black-30)',
                iconColor: 'var(--color-black-30)',
            },
        },
    },
};

export const RoundedFlatButtonStyle = (
    variantType: TFlatButtonVariantType = 'secondary',
    size: TButtonSize = 'medium'
) => {
    const config = BUTTON_CONFIG.variants[variantType];
    const padding = BUTTON_CONFIG.sizes[size];

    return {
        self: css`
      border-radius: 30px;
      border: none;
      padding: ${padding};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      max-width: 100%;
      background: ${config.default.background};
      color: ${config.default.color};
      border: 2px solid ${config.default.borderColor};
      position: relative;

      & .icon {
        width: 20px;
        height: 20px;
        transition: none;
        fill: ${config.default.iconColor};
      }

      &:disabled {
        color: ${config.disabled.color};
        cursor: default;
        opacity: 0.6;

        & .icon {
          fill: ${config.disabled.iconColor};
        }
      }

      &:active {
        background: ${config.pressed.background};
        
        .text {
          font-weight: 700;
        }
      }

      &.active,
      &.selected {
        .text {
          font-weight: 700;
        }

        color: ${config.pressed.color};
        iconColor: ${config.pressed.iconColor};
        border-color: ${config.pressed.borderColor};
      }

      &:not(:disabled) {
        &:hover {
          color: ${config.hover.color};
          iconColor: ${config.hover.iconColor};
          background: ${config.hover.background};
        }

        &:active {
          color: ${config.pressed.color};
          iconColor: ${config.pressed.iconColor};
          background: ${config.pressed.background};
          border-color: ${config.pressed.borderColor};
          
          .text {
            font-weight: 700;
          }
        }
      }

      & .text {
        &::after {
          content: attr(data-text);
          display: block;
          font-weight: 700;
          height: 0;
          width: max-content;
          white-space: nowrap;
          overflow: hidden;
          visibility: hidden;
        }
      }
    `,
    };
};