/** @jsxImportSource @emotion/react */
import {FC, useRef} from "react";
import { UserGroupsStyle } from './UserGroups.css';
import { Text, Icon, Tooltip } from "../../../index";
import { usePlusN } from '../../../../hooks';
import {useClientConfigurationsStore} from "../../../../stores";

type IProps = {
    groups: string[];
    keyString: string;
}

const UserGroups: FC<IProps> = (props) => {
    const {groups, keyString} = props;
    const isIDPConnected = useClientConfigurationsStore(state => state.isIDPConnected);

    const containerRef = useRef<HTMLDivElement | null>(null);

    const {
        isLimitCalculated,
        entitiesToDisplay: groupsToDisplay,
        entitiesToHide: groupsToHide,
    } = usePlusN({entities: groups, containerRef})

    const TextMarkup = (
        <>
            <div css={UserGroupsStyle.iconContainer}>
                <Icon iconName='PSUsersIcon' color='black-70'/>
            </div>
            <div css={UserGroupsStyle.contentContainer}>
            {isLimitCalculated && groupsToDisplay &&
                <Text key={`${keyString}-visible-groups`}>{groupsToDisplay?.join(', ')}</Text>}
            {groupsToHide?.length > 0 &&
                (<Tooltip
                    key={`${keyString}-+N`}
                    title={<Text>{groupsToHide?.join(', ')}</Text>}
                    placement='top'
                    arrow
                >
                    <span>{`+${groupsToHide.length}`}</span>
                </Tooltip>)}
            </div>
        </>
    )

    const NoGroupsTextMarkup = (
        <Tooltip
            title={<Text color='white'>To connect to identity platform go to: Integrations &gt; Identity
                Platforms</Text>}
            placement='top'
            disableHoverListener={isIDPConnected}
            arrow
        >
            <div css={UserGroupsStyle.naWrapper}>
                <div css={UserGroupsStyle.iconContainer}>
                    <Icon iconName='PSUsersIcon' color='black-40'/>
                </div>
                <div css={UserGroupsStyle.contentContainer}>
                    <Text color='black-40'>{isIDPConnected ? 'No group' : 'NA'}</Text>
                </div>
            </div>
        </Tooltip>
    )

    return (
        <div css={UserGroupsStyle.self} ref={containerRef}>
            {(!groups?.length || !isIDPConnected) ? NoGroupsTextMarkup : TextMarkup}
        </div>
    )
}

export default UserGroups;