import { css, Global } from '@emotion/react';
import { promptSecurityTheme } from '../styles/emotionTheme';
import { camelToKebabCase } from '../utils';
import { cssReset } from './cssReset';
import { customAgGridTheme } from './gridTheme';

const generateCssVariableFromObject = (obj: Record<string, any>, path: string[] = []): string => {
    return Object.entries(obj).flatMap(([key, value]): string[] | string => {
        const currentPath = [...path, camelToKebabCase(key)];
        const pathWithoutEmptyKeys = currentPath.filter(x => x !== '');
        const variableName = `--${pathWithoutEmptyKeys.join('-')}`;

        if (typeof value === 'object' && value !== null) {
            return generateCssVariableFromObject(value as Record<string, any>, currentPath);
        } else {
            return `${variableName}: ${value};`;
        }
    }).join(' ');
};

const rootStyles = css`
    :root  {
        ${generateCssVariableFromObject(promptSecurityTheme)}
    }
`;

const globalStyle = css`
    html {
        overflow: hidden;
    }
    
    body {
        color: var(--color-black) !important;
    }
`

const scrollStyle = css`
    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    *::-webkit-scrollbar-track {
        background: var(--color-transparent);
        border-radius: 3px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--color-black-70);
        border-radius: 3px;
        visibility: hidden;
    }
    
    *:hover::-webkit-scrollbar-thumb {
        visibility: visible;
    }

    /* AG Grid specific styles */
    .ag-root-wrapper *::-webkit-scrollbar,
    .ag-root-wrapper *::-webkit-scrollbar-track,
    .ag-root-wrapper *::-webkit-scrollbar-thumb {
        visibility: revert;
    }
`

const codeMirrorStyle = css`
        .cm-editor {
            border-radius: 20px;
            border: 1px solid var(--color-black-30);
        }

        .cm-focused {
                outline: none !important;
        }

        .cm-scroller {
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        .cm-content[contenteditable=true] {
            border-radius: inherit;
            padding: 10px 0;
        }

        .cm-gutterElement {
            font-size: 14px;
        }


`

const styles = [
    rootStyles,
    cssReset,
    customAgGridTheme,
    globalStyle,
    scrollStyle,
    codeMirrorStyle
]

export const GlobalStyles = () => <Global styles={styles} />;