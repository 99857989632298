import { create } from 'zustand';

interface PermissionsState {
    permissions: string[];
    setPermissions: (value: string[]) => void;
}

const usePermissionsStore = create<PermissionsState>((set, get) => ({
    permissions: [],
    setPermissions: (newValue) => set(() => ({ permissions: newValue })),
}));

export default usePermissionsStore;