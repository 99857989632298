/** @jsxImportSource @emotion/react */
import { FC, ReactNode } from "react";
import {Text, PSPopper} from "../../../index";

type StringOrReactNode = string | ReactNode;

type IProps = {
    headerName: StringOrReactNode;
    popperContent: StringOrReactNode;
}

const HeaderWithPopper: FC<IProps> = (props) => {
    const {headerName, popperContent} = props;

    const Content = typeof headerName === "string" ? <Text variant='smallBold'>{headerName}</Text> : headerName;

    return (
        <PSPopper tooltip={popperContent} >
            {Content}
        </PSPopper>
    )
}

export default HeaderWithPopper;