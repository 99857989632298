import { setCookie, getCookie } from '../../utils/cookies';
import { User } from '@frontegg/redux-store/auth/interfaces';
import { deleteCookie } from '../../utils/cookies';


export type TTenantDetails = {
    id: string;
    name: string;
}

export const setCurrentTenant = (newTenant: TTenantDetails): void => {
    setCookie('tenant_details', newTenant);
}

export const getCurrentTenant = (): TTenantDetails | null => {
    const tennat = getCookie('tenant_details');
    if (tennat) {
        try {
            return JSON.parse(tennat) as TTenantDetails;
        } catch (e) {
            return null;
        }
    }
    return null;
}

const getTenantsDetails = async (user: User, getSingleTenant: boolean = true): Promise<TTenantDetails[] | null> => {
    const activeTenantId = getCurrentTenant()?.id || user.tenantId;
    const request = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            activeTenantId: activeTenantId,
            getSingleTenant: getSingleTenant
        })
    };
    const response = await fetch('/api/tenants/get-tenants', request);
    if (response.status === 401) {
        return [];
    }
    const responseJson = await response.json();
    return responseJson;
}

export const getTenant = async (user: User): Promise<TTenantDetails | null> => {
    const tenants = await getEnvTenants(user);
    const currentTenant = getCurrentTenant();
    for (const tenant of tenants) {
        if (currentTenant && tenant.id === currentTenant.id) {
            return tenant;
        }
    }
    
    if (!tenants.length) {
        const response = await fetch(`/api/env-check`);
        if (!response.ok) {
            throw new Error(`Failure response from the tenant check API: got response code ${response.status}`);
        }
        const tenant = await response.json();

        deleteCookie('tenant_details');
        
        return tenant
    }
    return tenants?.[0];
}

export const getEnvTenants = async (user: User): Promise<TTenantDetails[]> => {
    const tenants = await getTenantsDetails(user, false);
    if (!tenants) {
        return [];
    }
    return tenants;
}