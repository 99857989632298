import {cloneDeep} from "lodash-es";

export function isStringArray(value: any): value is Array<string> {
  return (
    Array.isArray(value) &&
    value.every((item): item is string => typeof item === "string")
  );
}

export function isNumberArray(value: any): value is Array<number> {
  return (
    Array.isArray(value) &&
    value.every((item): item is number => typeof item === "number")
  );
}

export function isDateArray(value: any): value is Array<Date> {
  return (
    Array.isArray(value) &&
    value.every((item): item is Date => item instanceof Date)
  );
}

export function isNumber(str: string) {
  if (typeof str != "string") return false;
  return !isNaN(+str) && !isNaN(parseFloat(str));
}

export function isObjectsArray(
  value: any
): value is Array<{ [key: string]: any }> {
  return (
    Array.isArray(value) &&
    value.every((item) => typeof item === "object" && !Array.isArray(item))
  );
}

export type UniqueArray<T> = T extends readonly [infer X, ...infer Rest]
  ? InArray<Rest, X> extends true
    ? ["Encountered value with duplicates:", X]
    : readonly [X, ...UniqueArray<Rest>]
  : T;

export type InArray<T, X> = T extends readonly [X, ...infer _Rest]
  ? true
  : T extends readonly [X]
  ? true
  : T extends readonly [infer _, ...infer Rest]
  ? InArray<Rest, X>
  : false;

export function cloneArrayAndMoveElementToFrontByKeyValue<T>(array: T[], key: keyof T, value: any): T[] {
  const arrayClone = cloneDeep(array);
  const index = arrayClone.findIndex(obj => obj[key] === value);

  if (index > -1) {
    const [item] = arrayClone.splice(index, 1);
    arrayClone.unshift(item);
  }

  return arrayClone;
}