/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import TokenLimitationForm from './TokenLimitationForm';

const TokenLimitationProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode, connectorType } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Token Limitation"
            getValues={getValues}
            description={ connectorType === "homegrown" ? "Protect against denial of service or denial of wallet attacks" : "Analyze and optionally restrict the number of tokens" }
            formName="Token Limitation"
            Component={TokenLimitationForm}
            isAdvancedMode={isAdvancedMode}
            disableCheckbox={true}
            summary={`The Token Limitation Protection is designed to analyze and limit the number of tokens in a prompt or response. This feature serves multiple use cases:

1. Prevention of Denial of Wallet and Denial of Service (DoS): This feature prevents excessive token usage, which can lead to increased costs ('denial of wallet') or application downtime (DoS).
2. Token Count Visibility: This feature can be utilized to monitor the number of tokens processed by your genAI application, providing valuable insights into both prompt and response side operations.
3. Cost Control for Third-party genAI Applications: For organizations using commercial third-party genAI applications that charge per token, the Token Limit Protection feature is a useful tool for managing and controlling costs.

You can set token limits for prompts and responses in multiples of 8, or set the value to 0 for unlimited tokens. Token counting remains mandatory for our dashboards and cannot be turned off.`}
            excludePromptOrResponse={connectorType === 'developers' ? 'response' : undefined}
        />
    )
}

export default TokenLimitationProtection;