/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSCircledIconStyle = {
    self: (size: number, borderColor: string) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        
        border: 1px solid var(--color-${borderColor});
        border-radius: 50%;
        width: ${size}px;
        height: ${size}px;
    `,
};