/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const KeyValueRowStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0;
        border-bottom: 1px solid var(--color-black-40);
        width: 100%;
    
        &:last-of-type {
          border-bottom: none;
        }
  `,
    keyMinWidth: css`
        min-width: 150px;
  `,
    value: css`
        flex: 1;
        width: 100%;
    `,
  valueWrapper: css`
    width: 100%;
  `
};
