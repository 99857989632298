/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ColorThemeKeys } from '../../styles';

export const SingleSideStyle = {
    self: css`
        display: flex;
        gap: 15px;
        height: 90%;
    `,
    dataContainer: css`
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    `,
    topContainer: (shouldPadTop: boolean) => css`
        padding-top: ${shouldPadTop ? '5px' : 0};
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
    `,
    bottomContainer: (center: boolean) => css`
        display: flex;
        justify-content: ${ center ? 'center': 'start' };
        align-items: center;
        gap: 15px;
    `,
    numberContainer: (center: boolean) => css`
        display: flex;
        justify-content: ${ center ? 'center': 'start' };
        padding-top: 10px;
        padding-bottom: 5px;
    `,
    numberContent: (reduceTextSize: boolean) => css`
        font-size: ${reduceTextSize ? 30 : 40}px;
        font-weight: 700;
        line-height: 30px;
    `,
    skeletonContainer: css`
        padding: 10px 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    `,
    skeleton: (color: ColorThemeKeys) => css`
        background: var(--color-${color});
    `,
};