/** @jsxImportSource @emotion/react */
import { Autocomplete, Chip, CircularProgress, PaperProps, TextField } from '@mui/material';
import React, { useCallback, useMemo, useRef } from 'react';
import { PSMenuItem, PSPaper, GenAiApplication } from '../../ui-kit';
import { Controller, Control, UseFormSetValue } from 'react-hook-form';
import { GenAiApplicationAdditionStatus, GenAiApplicationType, GetApplicationsGlobalSettingsQuery } from '../../gql/generated/graphql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { graphql, createGraphQLClient } from '../../gql';
import AddCustomApplicationDialog from './AddCustomApplicationDialog';
import { useDebounce } from 'react-use';
import { uniqBy } from 'lodash';

const getGenAiApps = graphql(`
    query GetGenAiApps($input: GetGenAiApplicationsInput!) {
        getGenAiApplications(input: $input) {
            genAiApplicationsWithAdditionStatus {
                genAiApplication {
                    logo
                    id
                    faviconUrl
                    name
                    domain
                    isCustom
                }
            }
        }
    }
`);

const mutateAddCustomApplication = graphql(`
    mutation MutateAddCustomApplication($input: createCustomGenAiApplicationInput!) {
        createCustomGenAiApplication(input: $input) {
            id
        }
    }
`);

type IProps = {
    globalSettings: NonNullable<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings']>;
    applicationControl: Control<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings'], any>;
    setApplicationFormValue: UseFormSetValue<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings']>;
    disabled?: boolean;
};

const AppsNotToTrack: React.FC<IProps> = (props) => {
    const { globalSettings, applicationControl, setApplicationFormValue, disabled } = props;

    const queryClient = useQueryClient();
    const [isCustomAppDialogOpen, setIsCustomAppDialogOpen] = React.useState(false);
    const [searchTextInput, setSearchTextInput] = React.useState('');
    const [debouncedSearchTextInput, setDebouncedSearchTextInput] = React.useState('');
    const [, cancel] = useDebounce(() => {
        setDebouncedSearchTextInput(searchTextInput);
        return () => cancel();
    }, 500, [searchTextInput]);

    const { data: appsData, isLoading: isAppsLoading } = useQuery({
        queryKey: ['appsNotToTrack', debouncedSearchTextInput],
        queryFn: async ({ signal }) => {
            const client = createGraphQLClient(signal);
            const { getGenAiApplications } = await client.request(getGenAiApps, {
                input: {
                    Pagination: {
                        pageNumber: 1,
                        pageSize: 20,
                    },
                    Filter: {
                        additionStatus: [GenAiApplicationAdditionStatus.NotAdded],
                        applicationType: [GenAiApplicationType.AiApplication, GenAiApplicationType.AiComponent],
                        searchQuery: debouncedSearchTextInput,
                    },
                },
            });
            return getGenAiApplications.genAiApplicationsWithAdditionStatus || [];
        },
        refetchOnMount: true,
    });

    const { data: appsByIdsData, isLoading: isAppsByIdsLoading } = useQuery({
        queryKey: ['appsNotToTrackByIds'],
        queryFn: async ({ signal }) => {
            const client = createGraphQLClient(signal);
            const { getGenAiApplications } = await client.request(getGenAiApps, {
                input: {
                    Filter: {
                        applicationsId: globalSettings.ignoredGenAiApplicationIds,
                        applicationType: [GenAiApplicationType.AiApplication, GenAiApplicationType.AiComponent],
                    },
                    Pagination: {
                        pageNumber: 1,
                        pageSize: globalSettings.ignoredGenAiApplicationIds.length,
                    },
                }
            });
            return getGenAiApplications;
        },
        select: (data) => data?.genAiApplicationsWithAdditionStatus || [],
        enabled: globalSettings.ignoredGenAiApplicationIds.length > 0,
        refetchOnMount: true,
    });

    const mutateAddCustomApp = useMutation({
        mutationFn: async (input: { name: string; domain: string }) => {
            const client = createGraphQLClient();
            const { createCustomGenAiApplication } = await client.request(mutateAddCustomApplication, { input });
            const customApplicationId = createCustomGenAiApplication?.id;
            if (!customApplicationId) return;
            setApplicationFormValue('ignoredGenAiApplicationIds', [...globalSettings.ignoredGenAiApplicationIds, customApplicationId], {
                shouldDirty: true,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appsNotToTrack'] });
            setIsCustomAppDialogOpen(false);
        }
    })

    const applicationCache = useRef<Record<string, { id: string; name: string; domain: string; logo?: string | null; faviconUrl?: string | null; isCustom: boolean; }>>({});

    const data = useMemo(() => {
        const uniqueData = uniqBy([...(appsByIdsData || []), ...(appsData || [])],
            (item) => item.genAiApplication.id
        );

        uniqueData.forEach(item => {
            applicationCache.current[item.genAiApplication.id] = item.genAiApplication;
        });

        return uniqueData;
    }, [appsByIdsData, appsData]);

    const isLoading = isAppsLoading || isAppsByIdsLoading;
    const applicationsIds = useMemo(() => data.map((x) => x.genAiApplication.id), [data]);

    const getApplicationById = useCallback((id: string) => {
        const cachedApp = applicationCache.current[id];
        if (cachedApp) {
            return { genAiApplication: cachedApp };
        }
        return data.find((x) => x.genAiApplication.id === id) ||
            { genAiApplication: { id, name: 'Unknown App', domain: '' } };
    }, [data]);

    const getApplicationLabel = useCallback(
        (id: string) => {
            const app = getApplicationById(id);
            return (
                <GenAiApplication value={{ ...app?.genAiApplication, name: app?.genAiApplication.name }} iconSize={20} includeAppDomain />
            );
        },
        [getApplicationById]
    );

    const addCustomApplication = async (name: string, domain: string) => {
        await mutateAddCustomApp.mutateAsync({ name, domain });
    }

    const addAppAlreadyExists = (application: NonNullable<ReturnType<typeof getApplicationById>>) => {
        setApplicationFormValue('ignoredGenAiApplicationIds', [...globalSettings.ignoredGenAiApplicationIds, application.genAiApplication.id], {
            shouldDirty: true,
        });
    }

    return (
        <>
            <Controller
                name='ignoredGenAiApplicationIds'
                control={applicationControl}
                defaultValue={globalSettings.ignoredGenAiApplicationIds}
                disabled={disabled}
                render={({ field: {ref, ...rest} }) => (
                    <Autocomplete
                        {...rest}
                        multiple
                        disableCloseOnSelect
                        options={applicationsIds}
                        onChange={(_, value) => rest.onChange(value)}
                        filterOptions={(options, state) => options}
                        renderOption={(props, option, { selected }) => (
                            <PSMenuItem {...props} key={option} type='checkbox' selected={selected}>
                                {getApplicationLabel(option)}
                            </PSMenuItem>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((optionId, index) => (
                                <Chip
                                    {...getTagProps({ index })}
                                    variant='outlined'
                                    size='small'
                                    label={getApplicationLabel(optionId)}
                                    key={optionId}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={ref}
                                variant='outlined'
                                placeholder='Add genAi apps'
                                size='small'
                                onKeyDown={(e) => e.key === 'Backspace' && e.stopPropagation()}
                                value={searchTextInput}
                                onChange={(e) => setSearchTextInput(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoading && <CircularProgress color="inherit" size={20} />}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        PaperComponent={AddNewCustomApp}
                        slotProps={{
                            paper: {
                                //@ts-ignore
                                setIsCustomAppDialogOpen: setIsCustomAppDialogOpen,
                            }
                        }}
                        noOptionsText={isLoading ? 'Loading...' : 'No apps found'}
                    />
                )}
            />

            <AddCustomApplicationDialog
                isOpen={isCustomAppDialogOpen}
                closeDialog={() => setIsCustomAppDialogOpen(false)}
                addCustomApp={addCustomApplication}
                addAppAlreadyExists={addAppAlreadyExists}
            />
        </>
    );
};

export default AppsNotToTrack;

type AddNewCustomAppProps = PaperProps & {
    setIsCustomAppDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const AddNewCustomApp: React.FC<AddNewCustomAppProps> = (props) => {
    const { children, setIsCustomAppDialogOpen } = props;
    return (
        <PSPaper>
            {children}
            <PSMenuItem onClick={() => setIsCustomAppDialogOpen!(true)} onMouseDown={(event) => { event.preventDefault(); }} type='custom' iconName='PSAddIcon'>
                Add New Custom App
            </PSMenuItem>
        </PSPaper>
    )
}