/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSCodeMirrorStyle = {
    self: css`
        position: relative;
    `,
    copyButton: css`
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 100;
        padding: 5px 10px;
    `
}