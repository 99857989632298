/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import PromptLeakDetectorForm from './PromptLeakDetectorForm';

const PromptLeakDetectorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Prompt Leak Detector"
            getValues={getValues}
            description="Prevent the exposure the system prompt of your GenAI application."
            formName="Prompt Leak Detector"
            Component={isAdvancedMode ? PromptLeakDetectorForm : undefined}
            isAdvancedMode={isAdvancedMode}
            summary={`The Prompt Leak Detector is designed to prevent the unintentional exposure of your system prompt. If your system prompt is inadvertently leaked, it could expose your GenAI application and make it more susceptible to various attacks by malicious threat actors, including prompt injection and jailbreaking.`}
        />
    )
}

export default PromptLeakDetectorProtection;