import { DateTime } from "luxon";
import { TDateSelection, TPredefinedDateOrTimeSelection } from '../contexts'

export const dateStringISOToFormatted = (dateString: string) => {
  return DateTime.fromISO(dateString).toFormat("LLL dd, yyyy | HH:mm:ss");
};

export const dateStringISOToFormattedDateOnly = (dateString: string) => {
  return DateTime.fromISO(dateString).toFormat("LLL dd, yyyy");
};
export const getDisplayedTimePeriodText = (dateContextObj: TDateSelection) => {
  const {period, dates} = dateContextObj;

  switch (period){
    case 'today': return 'day';
    case 'yesterday': return 'day';
    case 'last7days': return '7 days';
    case 'last-month': return 'month';
    case 'last-year': return 'year';
  }

  const start = DateTime.fromJSDate(dates[0]);
  const end = DateTime.fromJSDate(dates[1]);

  const numberOfDays = Math.round(end.diff(start, 'days').as('days'));

  if (numberOfDays === 1) return 'day'
  return `${numberOfDays} days`
}

export const getTimePeriodDescriptionsForDashboardWidgets = (
    period: "custom" | TPredefinedDateOrTimeSelection,
    type:  "relative" | "absolute",
    date: TDateSelection
) => {
  const isSingleRelativeDay = period === 'today' || period === 'yesterday';
  const prefixForTimePeriodText = type === 'relative' ? 'in the last' : 'in selected';
  const timePeriodText = type === 'relative' ? getDisplayedTimePeriodText(date) : ' date range';

  return {
    footerDescription: `${isSingleRelativeDay ? period : prefixForTimePeriodText}${isSingleRelativeDay ? '' : ` ${timePeriodText}`}`,
    trendDescription: getDisplayedTimePeriodText(date),
  }
}
