

import { Box } from '@mui/material';
import { PoliciesViewer } from './PoliciesViewer';
import { PolicyAdd } from './PolicyAdd';
import { useState } from 'react';
import { PopupMessage } from '../../components/PopupMessage';
import { PolicyTypes } from '../../gql/generated/graphql';

type IProps = {
    policyType?: PolicyTypes;
}

export const ManagePolicies: React.FC<IProps> = (props) => {
    const { policyType } = props;
    const [openPopupMessage, setOpenPopupMessage] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');
    const handlePopupMessageOpen = (title: string, text: string) => {
        setPopupTitle(title);
        setPopupText(text);
        setOpenPopupMessage(true);
    }

    const handlePopupMessageClose = () => {
        setOpenPopupMessage(false);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <PoliciesViewer handlePopupMessage={handlePopupMessageOpen} policyType={policyType} />
            <PolicyAdd handlePopupMessage={handlePopupMessageOpen} policyType={policyType} />
            <PopupMessage open={openPopupMessage} title={popupTitle} text={popupText} handlePopupMessageClose={handlePopupMessageClose} />
        </Box>
    )

}

export default ManagePolicies;