/** @jsxImportSource @emotion/react */
import { Autocomplete, AutocompleteChangeReason, AutocompleteProps, PaperProps, TextField } from '@mui/material';
import React, { useState } from 'react';
import Chip from '../../Chip/Chip';
import PSMenuItem from '../../PSMenuItem/PSMenuItem';
import PSPaper from '../../PSPaper/PSPaper';
import {RefCallBack} from "react-hook-form";


type BaseProps = {
    optionsObject: Record<string, string>;
    textFieldLabel: string;
    limitTags?: number;
    outsideOnChange?: (value: string | string[]) => void;
    ref: RefCallBack;
} & Omit<AutocompleteProps<any, any, any, any>, 'options' | 'renderInput' | 'limitTags'>;

type ConditionalProps =
    | {
        includeSelectAll: true;
    }
    | {
        includeSelectAll?: false;
    };

type IProps = BaseProps & ConditionalProps;

const PSAutocomplete: React.FC<IProps> = (props) => {
    const { optionsObject, ref, textFieldLabel, includeSelectAll = false, limitTags = 5, outsideOnChange, value, disableCloseOnSelect = true, ...autoCompleteProps } = props;

    const [internalValue, setInternalValue] = useState<IProps['value']>([]);

    const handleChange = (_: any, newValue: IProps['value'], reason: AutocompleteChangeReason) => {
        setInternalValue(newValue);
        if (outsideOnChange && newValue) {
            outsideOnChange(newValue);
        }
    };

    const autoCompleteValue = value || internalValue;

    return (
        <Autocomplete
            {...autoCompleteProps}
            disableCloseOnSelect={disableCloseOnSelect}
            size='small'
            limitTags={limitTags}
            value={autoCompleteValue}
            options={Object.keys(optionsObject)}
            getOptionLabel={(option) => optionsObject[option as keyof typeof optionsObject]}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={handleChange}
            renderInput={(params) =>
                <TextField placeholder='Search...' label={textFieldLabel} inputRef={ref} {...params} />
            }
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        variant='outlined'
                        size='small'
                        label={optionsObject[option as keyof typeof optionsObject]}
                        key={option}
                    />
                ))
            }
            renderOption={(props, option, { selected }) => (
                <PSMenuItem {...props} key={option} type={autoCompleteProps.multiple ? 'checkbox' : 'icon'} selected={selected}>{optionsObject[option as keyof typeof optionsObject]}</PSMenuItem>
            )}
            PaperComponent={includeSelectAll ? SelectAllWrapper : undefined}
            slotProps={
                {
                    ...autoCompleteProps.slotProps,
                    paper: {
                        ...autoCompleteProps.slotProps?.paper,
                        //@ts-ignore
                        optionsObject: optionsObject,
                        //@ts-ignore
                        autoCompleteValue: autoCompleteValue,
                        //@ts-ignore
                        handleChange: handleChange,
                    }
                }
            }
        />
    )
}

export default PSAutocomplete;


type ISelectAllWrapperProps = {
    optionsObject?: Record<string, string>;
    autoCompleteValue?: string[];
    handleChange?: (event: any, newValue: string[]) => void;
} & PaperProps;

const SelectAllWrapper: React.FC<ISelectAllWrapperProps> = (props) => {
    const { optionsObject, autoCompleteValue, handleChange, children, ...restPaperProps } = props;

    const allSelected = autoCompleteValue!.length === Object.keys(optionsObject!).length;

    const handleSelectAll = () => {
        handleChange!(null, allSelected ? [] : Object.keys(optionsObject!));
    };

    return (
        <PSPaper {...restPaperProps}>
            {Object.keys(optionsObject || []).length > 1 && <div onMouseDown={(e) => e.preventDefault()} style={{ borderBottom: '1px solid var(--color-black-20)' }}>
                <PSMenuItem type="checkbox" selected={allSelected} onClick={handleSelectAll}>
                    Select All
                </PSMenuItem>
            </div>}
            {children}
        </PSPaper>
    );
};