/** @jsxImportSource @emotion/react */
import { Checkbox, ToggleButton, ToggleButtonGroup, TextField, Box } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import React, { useState } from 'react';
import { MacOsIcon, PSAgentOutputImage, SupportedPlatformTable, WindowsIcon } from '../../../assets/images';
import { useConnectorContext } from '../../../contexts';
import { Icon, PSBox, PSButton, PSCodeMirror, PSCopyText, PSFormControlLabel, Text } from '../../../ui-kit';
import { DevelopersDeploymentsStyle } from './DevelopersDeployments.css';
import { css } from '@emotion/react';
import { langs } from '@uiw/codemirror-extensions-langs';
import { censorStringEnd } from '../../../utils';
import { toast } from 'react-toastify';
import { downloadFileWithProgress } from '../../../utils';
import { codeMirrorTheme } from '../../../styles';

type OSInfo = {
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

type OperatingSystem = 'windows' | 'macos' | 'macos_intel';

const OPERATING_SYSTEMS: Record<OperatingSystem, OSInfo> = {
    windows: { label: 'Windows', icon: WindowsIcon },
    macos: { label: 'Mac - Apple silicon', icon: MacOsIcon },
    macos_intel: { label: 'Mac - Intel', icon: MacOsIcon },
};

type IProps = {};

const origin = window.location.origin;

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Text>{`${Math.round(props.value)}%`}</Text>
            </Box>
        </Box>
    );
}

const processUpstreamProxy = (upstreamProxy: string) => {
    if (upstreamProxy.startsWith('http://') || upstreamProxy.startsWith('https://')) {
        upstreamProxy = upstreamProxy.replace('http://', '').replace('https://', '');
    }
    if (upstreamProxy.endsWith('/')) {
        upstreamProxy = upstreamProxy.slice(0, -1);
    }
    if (upstreamProxy.includes(':')) {
        upstreamProxy = upstreamProxy.replace(':', '+');
    }
    return upstreamProxy;
}

const processDomain = (domain: string) => {
    if (domain.startsWith('http://') || domain.startsWith('https://')) {
        domain = domain.replace('http://', '').replace('https://', '');
    }
    if (domain.endsWith('/')) {
        domain = domain.slice(0, -1);
    }
    if (domain.includes(':')) {
        domain = domain.split(':')[0];
    }
    return domain;
}

const DevelopersDeployments: React.FC<IProps> = (props) => {
    const { } = props;

    const [selectedOS, setSelectedOS] = useState<OperatingSystem>('windows');
    const [upstreamProxy, setUpstreamProxy] = useState('');
    const [progress, setProgress] = useState(0);

    const { connector } = useConnectorContext();
    const [showApiKey, setShowApiKey] = useState(false);
    const [showUpstreamProxy, setShowUpstreamProxy] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    if (!connector) return null

    const connectorIdCensored = censorStringEnd(connector.id, 12);
    const apiKey = showApiKey ? connector.id : connectorIdCensored;

    const handleCopyText = () => {
        const copyText = `curl ${origin}/api/protect-native-apps/get_apps -H "app-id: ${connector.id}"`
        navigator.clipboard.writeText(copyText);
        setIsCopied(true);
    }
    const handleConfigDownload = async () => {
        const installerURL = `https://ps-downloads.s3.eu-north-1.amazonaws.com/release/latest/${selectedOS}/prompt_agent_installer.${selectedOS === 'windows' ? 'msi' : 'pkg'}`
        const agentFileName = `prompt_agent_installer_${upstreamProxy}_${connector.id}_${processDomain(origin)}.${selectedOS === 'windows' ? 'msi' : 'pkg'}`;
        try {
            await downloadFileWithProgress(
                installerURL,
                agentFileName,
                (progress) => {
                    setProgress(progress);
                }
            );
        } catch (error) {
            toast.error('Error downloading the agent installer');
        }
        toast.success('Agent installer downloaded successfully');
    };

    return (
        <div css={DevelopersDeploymentsStyle.self}>
            <div>
                <Text variant='header2'>Deployment instructions</Text>
                <Text variant='small' color='black-70'>How to deploy Prompt Security Agent for Developers</Text>
            </div>

            <PSBox padding={30}>
                <div css={DevelopersDeploymentsStyle.title}>
                    <Text variant='header2'>Agent</Text>
                </div>
                <div css={DevelopersDeploymentsStyle.sectionContainer}>
                    <Text variant='bold'>To Download the Agent, please select your Operating System</Text>
                    <ToggleButtonGroup
                        value={selectedOS}
                        onChange={(_, os) => os && setSelectedOS(os)}
                        exclusive
                        css={DevelopersDeploymentsStyle.sectionSelectContainer}
                    >
                        {Object.entries(OPERATING_SYSTEMS).map(([value, os]) => (
                            <ToggleButton key={value} value={value} css={DevelopersDeploymentsStyle.toggleButton}>
                                <ToggleButtonIcon Icon={os.icon} label={os.label} />
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: "5px" }}>
                        <PSFormControlLabel label='Use Upstream Proxy' control={
                            <Checkbox
                                checked={showUpstreamProxy}
                                onChange={(e) => setShowUpstreamProxy(e.target.checked)}
                                color='primary'
                            />
                        } />
                        {showUpstreamProxy && (
                            <TextField size='small' onChange={e => setUpstreamProxy(processUpstreamProxy(e.target.value))} sx={{ minWidth: "300px" }} placeholder="proxy.prompt.security:8080" label="Proxy URL" />
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Icon iconName='PSCriticalIcon' color='purple-70' />
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                            <Text variant='bold'>Do not change the file name</Text>
                            <Text >as it includes configuration parameters critical for a successful installation</Text>
                        </Box>
                    </Box>
                    <PSButton variant='filled' iconName='PSDownloadIcon' onClick={handleConfigDownload} isLoading={progress > 0 && progress < 100}>
                        Download {OPERATING_SYSTEMS[selectedOS].label} Agent Installer
                    </PSButton>
                    {progress > 0 && progress < 100 &&
                        (<Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel value={progress} />
                        </Box>)}
                </div>

                <div css={DevelopersDeploymentsStyle.sectionContainer}>
                    <Text css={css`margin-top: 20px;`} variant='bold'>Checking Environment Compatibility Before Deploying the Agent</Text>
                    <Text>Ahead of deploying the Agent, it is essential to ensure that your environment can communicate and access the relevant Prompt Security region domain. Follow the steps below to verify this.</Text>
                </div>

                <div css={DevelopersDeploymentsStyle.apiKey}>
                    <PSFormControlLabel label='Display API Key:' control={
                        <Checkbox
                            checked={showApiKey}
                            onChange={(e) => setShowApiKey(e.target.checked)}
                            color='primary'
                        />
                    } />
                    <PSCopyText label={apiKey} text={connector.id} />
                </div>


                <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                    <ol css={css`list-style: decimal; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                        <li><Text>{selectedOS === 'windows' ? 'Open CMD (Windows+R → CMD).' : 'Open Terminal.'}</Text></li>
                        <li><Text>Run the following command:</Text></li>
                    </ol>
                    <PSCodeMirror
                        readOnly
                        theme={codeMirrorTheme}
                        extensions={[langs.shell()]}
                        value={`curl ${origin}/api/protect-native-apps/get_apps -H "app-id: ${apiKey}"`}
                        copyButton
                        copyText={`curl ${origin}/api/protect-native-apps/get_apps -H "app-id: ${connector.id}"`}
                    />
                    <Text><b>Output</b>: The output should match the screenshot provided below.</Text>
                    <img src={PSAgentOutputImage} alt="agent-output" css={css`width: max(50%, 1000px);`} />
                </div>
            </PSBox>

            <PSBox padding={30}>
                <Text css={css`margin-bottom: 10px;`} variant='bold'>Supported Platforms</Text>
                <SupportedPlatformTable css={css`width: min(100%, 850px);`} />
            </PSBox>
        </div>
    )
}

export default DevelopersDeployments;

type ToggleButtonIconProps = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string;
}

export const ToggleButtonIcon: React.FC<ToggleButtonIconProps> = ({ Icon, label }) => (
    <div css={css`overflow: hidden; gap: 5px; display: flex; flex-direction: column; align-items: center;`}>
        <div css={DevelopersDeploymentsStyle.iconContainer}>
            <Icon width={50} height={50} />
        </div>
        <Text variant='small'>{label}</Text>
    </div>
);
