/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const SortHeaderStyle = {
  self: (forceDisable: boolean | undefined) => css`
    display: flex;
    align-items: start;
    cursor: ${forceDisable ? 'auto' : 'pointer'};
    user-select: none;
    gap: 5px;
    width: 100%;
  `,
};
