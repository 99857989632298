import { datadogRum } from '@datadog/browser-rum';
import { User } from '@frontegg/redux-store/auth/interfaces';
import { graphql } from '../gql';
import { createGraphQLClient } from '../gql/client';
import { TTenantDetails } from '../routes/ManageTenants/tenants';
import { v4 as uuidv4 } from 'uuid';

const datadogRumQuery = graphql(`
    query datadogRumQuery {
        getEnvironmentSettings {
            datadog {
                enabled
                clientMonitor {
                    applicationId
                    clientToken
                    env
                }
            }
            platformVersion
        }
    }
`);

export const datadogRumInitialize = async (user: User, userTenant: TTenantDetails) => {
    try {
        const client = createGraphQLClient();
        const data = await client.request(datadogRumQuery, undefined, {
            'Authorization': `Bearer ${user.accessToken}`
        });

        if (data?.getEnvironmentSettings?.datadog?.enabled) {
            const datadogSettings = data.getEnvironmentSettings.datadog;
            if (!datadogSettings.enabled || !datadogSettings.clientMonitor) {
                console.warn('Datadog client settings not found');
                return;
            }

            datadogRum.init({
                applicationId: datadogSettings.clientMonitor.applicationId,
                clientToken: datadogSettings.clientMonitor.clientToken,
                site: 'datadoghq.com',
                service: 'rum',
                version: data.getEnvironmentSettings.platformVersion,
                env: datadogSettings.clientMonitor.env || undefined,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                defaultPrivacyLevel: 'mask',
                trackResources: true,
                trackLongTasks: true,
                trackUserInteractions: true,
                enablePrivacyForActionName: true
            });

            datadogRum.setUser({
                id: user.id,
                email: user.email,
                name: user.name,
                psSessionId: uuidv4(),
                tenantId: userTenant.id,
                tenantName: userTenant.name
            });
        }
    } catch (error) {
        console.warn('Error initializing Datadog Client');
    }
};

export const datadogRumUserIsSet = (): { id: string, tenantId: string | undefined, tenantName: string | undefined } | undefined => {
    const user = datadogRum.getUser();

    if (!user || !user.id) {
        console.warn('No user currently set in Datadog Client');
        return;
    }

    return { id: user.id.toString(), tenantId: user.tenantId?.toString(), tenantName: user.tenantName?.toString() };
}

export const datadogRumSetUserTenant = (newTenant: TTenantDetails): void => {
    const existingUser = datadogRumUserIsSet();

    if (!existingUser) {
        return;
    }

    if (existingUser.tenantId !== newTenant.id || existingUser.tenantName !== newTenant.name) {
        datadogRum.setUserProperty('tenantId', newTenant.id);
        datadogRum.setUserProperty('tenantName', newTenant.name);
    }
};

export const datadogRumStopSession = (): void => {
    if (datadogRumUserIsSet()?.id) {
        datadogRum.stopSession();
    }
}