/** @jsxImportSource @emotion/react */
import React from 'react';
import { Controller } from 'react-hook-form';
import { IProtectionFormProps, TCustomModeratorProtection, VENDORS } from '../Common';
import { CustomModeratorFormStyle } from './CustomModeratorForm.css';
import { FormControl, InputLabel, Select, Slider, TextField } from '@mui/material';
import { PSMenuItem, Text } from '../../../../ui-kit';

type IProps = {

};

const CustomModeratorForm: React.FC<IProtectionFormProps<TCustomModeratorProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;

    return (
        <div css={CustomModeratorFormStyle.self}>
            <Controller
                name={`${formName}.judger_prompt`}
                control={control}
                defaultValue={protection.judger_prompt}
                render={({ field: {ref, ...rest} }) => (
                    <TextField
                        {...rest}
                        inputRef={ref}
                        label="Judger Prompt"
                        size='small'
                        multiline
                    />
                )}
            />

            <Controller
                name={`${formName}.threshold`}
                control={control}
                defaultValue={protection.threshold}
                render={({ field }) => (
                    <div>
                        <Text>Threshold</Text>
                        <Slider {...field} min={0} max={1} step={0.05} valueLabelDisplay="auto"
                            marks={[
                                { value: 0, label: '0' },
                                { value: 0.25, label: '0.25' },
                                { value: 0.5, label: '0.5' },
                                { value: 0.75, label: '0.75' },
                                { value: 1, label: '1' },
                            ]}
                        />
                    </div>
                )}
            />

            <Controller
                name={`${formName}.vendor`}
                control={control}
                defaultValue={protection.vendor}
                render={({ field }) => (
                    <FormControl>
                        <InputLabel id="label">Vendor</InputLabel>
                        <Select
                            {...field}
                            labelId="label"
                            size='small'
                            label="Vendor"
                        >
                            {Object.entries(VENDORS).map(([key, value]) => (
                                <PSMenuItem key={key} value={key} >{value}</PSMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />



            {protection.vendor === 'openai' && (
                <React.Fragment>
                    <Controller
                        name={`${formName}.model`}
                        defaultValue={protection.model}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="Model"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        name={`${formName}.llm_api_key`}
                        defaultValue={protection.llm_api_key}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="API Key"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </React.Fragment>
            )}

            {protection.vendor === 'azure_openai' && (
                <React.Fragment>
                    <Controller
                        name={`${formName}.model`}
                        defaultValue={protection.model}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="Model"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        name={`${formName}.llm_api_key`}
                        defaultValue={protection.llm_api_key}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="API Key"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        name={`${formName}.azure_endpoint`}
                        defaultValue={protection.azure_endpoint}
                        control={control}
                        render={({ field: {ref, ...rest} }) => (
                            <TextField
                                {...rest}
                                inputRef={ref}
                                label="Azure Endpoint"
                                size='small'
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </React.Fragment>
            )}
        </div>
    )
}

export default CustomModeratorForm;