import React, { useEffect, useState } from 'react';
import { Typography, Box, Card } from '@mui/material';
import { useParams } from 'react-router-dom';
import { graphql, createGraphQLClient } from '../../gql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { ApplicationTypes, Rule, RuleActions, RuleContentKeys, RuleContentOperators, SecurityApproach } from '../../gql/generated/graphql';
import { DraggableRules } from './DraggableRules';
import { EditButtons } from '../../components/EditButtons';
import { PopupMessage } from '../../components/PopupMessage';
import { SingleRuleRegular } from './SingleRules/singleRuleRegular';
import { SingleRuleBrowserExtension } from './SingleRules/singleRuleBrowserExtension';
import { PSButton, Text } from '../../ui-kit';
import AddCustomApplicationDialog from '../../routes-new/GenAiMgmt/AddCustomApplicationDialog';
import { toast } from 'react-toastify';
import { useCheckPermission } from "../../hooks/usePermissionCheck";


const queryApplications = graphql(`
    query Applications {
        applications {
            name
            applicationType
        }
    }
`);

const queryRules = graphql(`
    query RulesByApplication($applicationName: String!) {
        rulesByApplication(applicationName: $applicationName) {
            action
            subActions
            content
            description
            id
            policy {
                id
                name
            } application {
                applicationType
            }
        }
    }
`);

const getExtensionSecurityApproach = graphql(`
    query GetSecurityApproach {
        getGenAiApplicationsGlobalSettings {
            securityApproach
        }
    }
`)

const mutationOverrideRules = graphql(`
    mutation OverrideRules($rules: [RulesOverrideRule!]!, $applicationName: String!) {
        overrideRules(input: { rules: $rules, applicationName: $applicationName }) {
            id
        }
    }
`)

const mutationCreateCustomGenAiApplication = graphql(`
    mutation CreateCustomGenAiApplication($domain: String!, $name: String!) {
        createCustomGenAiApplication(input: { domain: $domain, name: $name }) {
            id
        }
    }
`)

export type TRule = Rule & { isNew?: boolean };

export const ManageApplicationRules = () => {
    let { applicationName, connectorName } = useParams();
    const queryClient = useQueryClient();
    const [localDraggableRules, setLocalDraggableRules] = useState<(TRule)[] | undefined>();
    const [localDefaultRule, setLocalDefaultRule] = useState<TRule | undefined>();
    const [editMode, setEditMode] = useState(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');
    const [openSavePopup, setOpenSavePopup] = useState<boolean>(false);
    const [isAddCustomAppDialogOpen, setIsAddCustomAppDialogOpen] = useState(false);
    const [displayDefaultRule, setDisplayDefaultRule] = useState(false);
    const hasPermissionUpdateRules = useCheckPermission('ps.rules.update.rules')

    const { data: application } = useQuery(
        {
            queryKey: ["applications"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { applications } = await client.request(queryApplications);
                return applications
            },
            select: (data) => data.find(x => x.name === applicationName || x.name === connectorName)
        }
    );

    const { data: rules } = useQuery(
        {
            queryKey: ["rulesByApplication", applicationName ?? connectorName],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const appName = applicationName ?? connectorName ?? '';
                const { rulesByApplication } = await client.request(queryRules, { applicationName: appName });
                return rulesByApplication as TRule[]
            }
        }
    );

    const { data: extensionSecurityApproach } = useQuery(
        {
            queryKey: ["extensionSecurityApproach"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { getGenAiApplicationsGlobalSettings } = await client.request(getExtensionSecurityApproach);
                return getGenAiApplicationsGlobalSettings.securityApproach
            },
            enabled: application?.applicationType === ApplicationTypes.Extension
        }
    );

    const overrideRules = useMutation({
        mutationFn: async (variables: any) => {
            const client = createGraphQLClient();
            await client.request(mutationOverrideRules, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['rulesByApplication', application?.name] })
        },
        onError: (error) => {
            const errorMessage = 'Failed to update rules';
            setPopupText(errorMessage);
            setPopupTitle('Rule Update Failed');
            setOpenSavePopup(true);
        },
    });

    const addCustomApp = useMutation({
        mutationFn: async (variables: any) => {
            const client = createGraphQLClient();
            await client.request(mutationCreateCustomGenAiApplication, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['querySuggestRuleContentValuesOrganizationGenAiApplications'] });
            toast.success('Custom GenAI Application created successfully');
        },
        onError: (error) => {
            const errorMessage = 'Failed to create custom GenAI Application';
            setPopupText(errorMessage);
            setPopupTitle('Create Custom Application Failed');
            setOpenSavePopup(true);
        },
    });

    useEffect(() => {
        if (rules !== undefined) {
            updateLocalState(rules);
            const defaultRuleIsIgnore = rules[0].action === RuleActions.Ignore;
            setDisplayDefaultRule(!defaultRuleIsIgnore);
        }
    }, [rules]);

    useEffect(() => {
        if (localDefaultRule !== undefined && localDraggableRules !== undefined) {
            const localRules = [localDefaultRule, ...localDraggableRules];
            setEditMode(!isEqual(localRules, rules))
        }
    }, [localDefaultRule, localDraggableRules, rules]);

    const updateLocalState = (localRules: TRule[]) => {
        const [defaultRule, ...draggableRules] = localRules;
        setLocalDefaultRule(defaultRule);
        setLocalDraggableRules(draggableRules);
    };

    const handleSavePopupClose = () => {
        setOpenSavePopup(false);
    };

    const handleSaveClick = () => {
        if (localDefaultRule === undefined || localDraggableRules === undefined) {
            return;
        }

        const rulesToSave: TRule[] = [localDefaultRule, ...localDraggableRules].map(x => {
            const { isNew, ...rest } = x;
            return rest;
        })

        const rules = rulesToSave.map(x => {
            if (x.content) {
                x.content.forEach((content: { key: RuleContentKeys, operator: RuleContentOperators, value: string[] }) => {
                    if (content.operator === RuleContentOperators.Is) {
                        if (content.key === RuleContentKeys.GenAiApplication) {
                            content.key = RuleContentKeys.GenAiApplicationVerificationStatus;
                        }
                    } else if (content.operator === RuleContentOperators.IsOne) {
                        if (content.key === RuleContentKeys.GenAiApplicationVerificationStatus) {
                            content.key = RuleContentKeys.GenAiApplication;
                        }
                    }
                })
            }
            return {
                description: x.description,
                content: x.content,
                action: x.action,
                subActions: x.subActions,
                policyId: x.policy?.id || null
            };
        });

        overrideRules.mutate({ applicationName: applicationName ?? connectorName, rules });
    };

    const handleCancelClick = () => {
        if (rules !== undefined) {
            updateLocalState(rules);
        }
    };

    const handleAddRuleClick = (applicationType: ApplicationTypes) => {
        if (localDraggableRules !== undefined && hasPermissionUpdateRules) {
            const newRule = {
                isNew: true,
                action: "",
                subActions: {
                    canBypassInspection: "",
                    canBypassBlock: "",
                    inspectionAction: "",
                    logAction: "",
                    coachAwareness: "",
                    warnBeforeDomainUsage: "",
                },
                description: "",
                id: uuidv4(),
                content: [
                    {
                        key: "",
                        operator: "",
                        value: []
                    }
                ],
                policy: {
                    id: "",
                    name: ""
                },
                application: {
                    applicationType
                }
            }
            const newLocalDraggableRules = [...localDraggableRules, newRule];
            setLocalDraggableRules(newLocalDraggableRules as any);
        }
    };

    const handleAddGenAiApplicationClick = () => {
        setIsAddCustomAppDialogOpen(true);
    };

    if (localDefaultRule === undefined || localDraggableRules === undefined || application === undefined) {
        return null;
    }

    return (
        <>
            <Box sx={{ flex: 1, paddingTop: '20px', overflow: 'auto' }}>
                {!connectorName && <Typography variant="h5" gutterBottom style={{ marginBottom: "30px" }}>
                    Manage Rules For Connector: '{application.name}'
                </Typography>}

                <DraggableRules rules={localDraggableRules} updateRules={setLocalDraggableRules} applicationType={application.applicationType} />

                {displayDefaultRule && <div>
                    <Typography style={{ marginTop: '40px', marginBottom: '20px' }} variant="h6" gutterBottom>Default Rule</Typography>
                    <Card sx={{ padding: '20px 55px 20px 75px', border: '1px solid var(--color-black-30)', boxShadow: 'none', borderRadius: '20px', marginBottom: '15px', marginRight: '20px' }}>
                        {application.applicationType !== ApplicationTypes.Extension && <SingleRuleRegular rule={localDefaultRule} updateRule={setLocalDefaultRule} isDefault applicationType={application.applicationType} />}
                        {application.applicationType === ApplicationTypes.Extension && <SingleRuleBrowserExtension rule={localDefaultRule} updateRule={setLocalDefaultRule} isDefault />}
                    </Card>
                </div>}
            </Box>


            <div style={{ display: 'flex', alignItems: 'center', borderTop: '1px solid var(--color-black-30)', paddingTop: '20px' }}>
                {application.applicationType === ApplicationTypes.Extension && extensionSecurityApproach !== SecurityApproach.Customized ?
                    <>
                        <Text><b>VIEW ONLY:</b> You cannot edit rules for this application. Security approach is set to standard mode</Text>
                    </>
                    : <>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            {hasPermissionUpdateRules && <PSButton variant='outlined' onClick={() => handleAddRuleClick(application.applicationType)}>Add Rule</PSButton>}
                            {application.applicationType === ApplicationTypes.Extension && <PSButton variant='outlined' onClick={() => handleAddGenAiApplicationClick()}>Add GenAI Application</PSButton>}
                        </div>

                        {hasPermissionUpdateRules && <EditButtons sx={{
                            marginLeft: 'auto',
                        }} editMode={editMode} handleCancelClick={handleCancelClick} handleSaveClick={handleSaveClick} />}
                    </>
                }
            </div>

            <PopupMessage title={popupTitle} text={popupText} open={openSavePopup} handlePopupMessageClose={handleSavePopupClose} />

            <AddCustomApplicationDialog
                isOpen={isAddCustomAppDialogOpen}
                addCustomApp={(appName, appDomain) => addCustomApp.mutate({ name: appName, domain: appDomain })}
                closeDialog={() => setIsAddCustomAppDialogOpen(false)}
                addAppAlreadyExists={() => toast.error('Application already added')}
            />
        </>
    )

}