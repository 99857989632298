import React, { createContext, useState } from 'react';

type DashboardContextType = {
    violationsCount: number | undefined;
    setViolationsCount: (value: number) => void;

    promptsCount: number | undefined;
    setPromptsCount: (value: number) => void;

    installedExtensionsCount: number | undefined;
    setInstalledExtensionsCount: (value: number) => void;

    employeesConnectorName: string | undefined;
    developersConnectorName: string | undefined;
}

export const DashboardsContext = createContext<DashboardContextType | null>(null);

type IProps = {
    children: React.ReactNode;
    employeesConnectorName: string | undefined;
    developersConnectorName: string | undefined;
};

export const DashboardsContextProvider: React.FC<IProps> = (props) => {
    const {children, employeesConnectorName, developersConnectorName} = props;

    const [violationsCount, setViolationsCount] = useState<number>();
    const [promptsCount, setPromptsCount] = useState<number>();
    const [installedExtensionsCount, setInstalledExtensionsCount] = useState<number>();


    const value = {
        violationsCount,
        setViolationsCount,
        promptsCount,
        setPromptsCount,
        installedExtensionsCount,
        setInstalledExtensionsCount,

        employeesConnectorName,
        developersConnectorName,
    }

    return (
        <DashboardsContext.Provider value={value}>
            {children}
        </DashboardsContext.Provider>
    );
};

export const useDashboardsContext = () => {
    const context = React.useContext(DashboardsContext);
    if (!context) {
        throw new Error('useDashboardsContext must be used within a DashboardsContextProvider');
    }

    return context;
};