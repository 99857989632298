/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TOverviewVariants } from "../PSOverview/types";

export const PSDoubleOverviewStyle = (variant: TOverviewVariants) => ({
    self: (minWidth: number | undefined, maxWidth: number | undefined) => css`
        ${minWidth ? css`min-width: ${minWidth}px;` : ''}
        ${maxWidth ? css`max-width: ${maxWidth}px;` : ''}
        width: 100%;
        height: 100%;
        border: ${variant === "dark" ? '' : '1px solid var(--color-black-30)'};
        border-radius: 15px;
        min-height: 155px;
        position: relative;
        overflow: hidden;
        background: ${variant === "dark" ? 'var(--color-gradient-purple)' : 'var(--color-white)'};
        container-type: inline-size;
        padding: 20px 20px 20px 30px;
    `,
    glow: css`
        z-index: unset;
        position: absolute;
        right: -18%;
        top: 20%;
        background: ${variant === "dark" ? 'var(--color-white-15)' : '#ba95e31f'};
        filter: blur(37px);
    `,
    errorContainer: css`
        border: 1px solid var(--color-black-30); 
        border-radius: 15px;
        height: 100%;
        background: var(--color-white);
    `,
    contentContainer: (maxContentWidth: number | undefined) => css`
        display: flex;
        align-items: center; 
        justify-content: space-around; 
        width: 100%; 
        height: 100%;
        ${maxContentWidth ? css`max-width: ${maxContentWidth}px;` : ''}
    `,
});