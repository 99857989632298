/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionFormProps, HARMFUL_CONTENT_MODERATOR_CATEGORIES, THarmfulContentModeratorProtection } from '../Common';
import { HarmfulContentModeratorFormStyle } from './HarmfulContentModeratorForm.css';
import { Controller } from 'react-hook-form';
import { Radio, RadioGroup } from '@mui/material';
import { PSAutocomplete, PSFormControlLabel, Text, PSPopper } from '../../../../ui-kit';

const HarmfulContentModeratorForm: React.FC<IProtectionFormProps<THarmfulContentModeratorProtection>> = (props) => {
    const { control, formName, protection } = props;

    return (
        <div css={HarmfulContentModeratorFormStyle.self}>
            <Controller
                name={`${formName}.categories`}
                control={control}
                defaultValue={protection.categories}
                render={({ field }) => (
                    <PSAutocomplete
                        {...field}
                        multiple
                        includeSelectAll
                        optionsObject={HARMFUL_CONTENT_MODERATOR_CATEGORIES}
                        textFieldLabel='Moderation Categories'
                        outsideOnChange={field.onChange}
                    />
                )}
            />

            <Controller
                name={`${formName}.threshold_mode`}
                defaultValue={protection.threshold_mode || 'balanced'}
                control={control}
                render={({ field }) => (
                    <div>
                        <Text>Moderation Level</Text>
                        <RadioGroup
                            {...field}
                            row
                            value={field.value}
                        >
                            <PSPopper tooltip='High Recall' >
                                <PSFormControlLabel value={'high_recall'} control={<Radio size='small' />} label="More Restrictive" />
                            </PSPopper>
                            
                            <PSPopper tooltip='Balanced' >
                                <PSFormControlLabel value={'balanced'} control={<Radio size='small' />} label="Balanced" />
                            </PSPopper>

                            <PSPopper tooltip='High Precision' >
                                <PSFormControlLabel value={'high_precision'} control={<Radio size='small' />} label="Less Restrictive" />
                            </PSPopper>
                        </RadioGroup>
                    </div>
                )}
            />
        </div>
    )
}

export default HarmfulContentModeratorForm;