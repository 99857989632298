/** @jsxImportSource @emotion/react */
import React, { useMemo, forwardRef } from 'react';
import { Button, ButtonProps } from '@mui/base';
import { RoundedFlatButtonStyle } from './RoundedFlatButton.css';
import { FilledButtonStyle } from './FilledButton.css';
import { SquareButtonStyle } from './SquareButton.css';
import Icon, { IconNames } from '../Icon/Icon';
import Text from '../Text/Text';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import { SerializedStyles } from '@emotion/react';

export type TFilledButtonVariantType = 'info' | 'critical';
export type TFlatButtonVariantType = 'positive' | 'negative' | 'secondary';

export type TButtonSize = 'small' | 'medium';

export type TButtonVariant = 'outlined' | 'filled' | 'flat' | 'square';

type BaseProps = Omit<{
    variant: TButtonVariant;
    children?: React.ReactNode;
    isLoading?: boolean;
    disabled?: boolean;
    placement?: 'start' | 'end';
    iconName?: IconNames;
    isActive?: boolean;
    size?: TButtonSize;
    css?: SerializedStyles;
} & ButtonProps, 'variantType'>;

// Separate complete type definitions for each variant
type FilledButtonProps = BaseProps & {
    variant: 'filled';
    variantType?: TFilledButtonVariantType;
};

type FlatButtonProps = BaseProps & {
    variant: 'flat';
    variantType?: TFlatButtonVariantType;
};

type OutlinedButtonProps = BaseProps & {
    variant: 'outlined';
};

type SquareButtonProps = BaseProps & {
    variant: 'square';
};

type IProps = FilledButtonProps | FlatButtonProps | OutlinedButtonProps | SquareButtonProps;

const PSButton = forwardRef<HTMLButtonElement, IProps>((props, ref) => {
    const {
        children,
        isLoading,
        iconName,
        placement,
        variant,
        isActive,
        className,
        size = 'medium',
        css,
        ...buttonProperties
    } = props;

    const ButtonStyle = useMemo(() => {
        switch (variant) {
            case 'flat':
                const { variantType = 'positive' } = props as FlatButtonProps;
                return RoundedFlatButtonStyle(variantType, size);
            case 'outlined':
            case 'filled': {
                const { variantType = 'info' } = props as FilledButtonProps;
                return FilledButtonStyle(variantType, size, variant);
            }
            case 'square':
                return SquareButtonStyle;
            default:
                throw new Error('Invalid variant');
        }
    }, [variant, (props as any)?.variantType, size]);

    return (
        <Button
            ref={ref}
            css={[ButtonStyle.self, css]}
            {...buttonProperties}
            disabled={isLoading || buttonProperties.disabled}
            className={clsx('ps-button', { 'active': isActive }, className)}
        >
            {isLoading && <>
                <CircularProgress color="inherit" size={20} />
            </>}
            {!isLoading && (
                <>
                    {iconName && (!placement || placement === 'start') && <Icon className="icon-start" iconName={iconName} />}
                    {children && <Text ellipsis variant='text' dataText={typeof children === 'string' ? children : undefined}>{children}</Text>}
                    {iconName && placement === 'end' && <Icon className="icon-end" iconName={iconName} />}
                </>
            )}
        </Button>
    );
});

export default PSButton;