/** @jsxImportSource @emotion/react */
import React from 'react';
import * as MUIIcons from '@mui/icons-material';
import { PSIcons } from '../../assets/icons';
import { IconStyle } from './Icon.css';
import { ColorThemeKeys } from '../../styles';
import { css } from '@emotion/react';

export type MUIIconNames = keyof typeof MUIIcons;
export type PSIconNames = keyof typeof PSIcons;
export type IconNames = PSIconNames | ''; //Removed MUI icons for now.

export type TIConSize = 'small' | 'medium' | 'large' | 'custom';

type BaseProps = {
    iconName?: IconNames;
    className?: string;
    color?: ColorThemeKeys;
};

type StandardProps = {
    iconSize?: TIConSize | number;
};

type CustomSizeProps = {
    iconSize: 'custom';
    width: number;
    height: number;
};

export type IProps = BaseProps & (StandardProps | CustomSizeProps);

const Icon: React.FC<IProps> = (props) => {
    const { iconName, className, iconSize = 20, color, width, height } = props as BaseProps & CustomSizeProps;

    const isCustom = iconSize === 'custom';

    const colorCss = css`
        color: var(--color-${color});
        fill: var(--color-${color});
    `;

    const IconComponent = React.useMemo(() => {
        if (!iconName) return null;

        const iconSizeStyles = isCustom ?  IconStyle.customIconSize(width, height): IconStyle.iconSize(iconSize);

        if (iconName in MUIIcons) {
            const MuiIcon = MUIIcons[iconName as MUIIconNames];
            return <MuiIcon css={[IconStyle.self, iconSizeStyles, color && colorCss]} className={`icon ${className ? className : ''}`} />;
        }

        if (iconName in PSIcons) {
            const PSIcon = PSIcons[iconName as PSIconNames];
            return <PSIcon css={[IconStyle.self, iconSizeStyles, color && colorCss]} className={`icon ${className ? className : ''}`} />;
        }

        return <span>ICON NAME NOT FOUND</span>;

    }, [isCustom, iconName, iconSize, color, className, colorCss, width, height]);

    return IconComponent;
};

export default Icon;
export type { IProps as IconProps };