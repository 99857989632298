import { datadogRumStopSession } from '../external-tools';
import { setCookie, getCookie, deleteCookie } from './cookies';
import { ContextHolder } from '@frontegg/react';

export const setFronteggJWTCookie = (token: string) => {
    setCookie('fronteggSessionToken', token);
}

export const getFronteggJWTCookie = (): string | null => {
    return getCookie('fronteggSessionToken');
}

export const removeFronteggJWTCookie = (): void => {
    deleteCookie('fronteggSessionToken');
}

export const setUserTokenLocalStorage = (token: string) => {
    localStorage.setItem('ps-user-token', token);
}

export const getUserTokenLocalStorage = (): string | null => {
    return localStorage.getItem('ps-user-token');
}

export const removeUserTokenLocalStorage = (): void => {
    localStorage.removeItem('ps-user-token');
}

export const addLoginState = (): void => {
    localStorage.setItem("loggedIn", "");
}

export const removeLoginState = (): void => {
    localStorage.removeItem("loggedIn");
}

export const logout = async (isLoggedIn: boolean = true) => {
    if (isLoggedIn) {
        await fetch('/api/access/register-logout');
    }
    const baseUrl = ContextHolder.getContext().baseUrl;
    const redirectUrl = window.location.origin;
    datadogRumStopSession();
    removeLoginState();
    removeUserTokenLocalStorage();
    removeFronteggJWTCookie();
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${redirectUrl}`;
};