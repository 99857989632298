import { css } from "@emotion/react";
import { TButtonSize, TButtonVariant, TFilledButtonVariantType } from "./PSButton";

const BUTTON_CONFIG = {
  sizes: {
    small: '5px 25px',
    medium: '10px 25px',
  },
  variants: {
    outlined: {
      default: {
        background: 'transparent',
        border: 'var(--color-blue-80)',
        color: 'var(--color-blue-80)',
        focusBorder: 'var(--color-blue-20)',
      },
      hover: {
        background: 'var(--color-black-20)',
        border: 'var(--color-blue-80)',
      },
      pressed: {
        background: 'var(--color-black-20)',
        border: 'var(--color-blue-20)',
      },
      focus: {
        border: 'var(--color-blue-20)',
      },
      disabled: {
        background: 'transparent',
        border: 'var(--color-black-30)',
        color: 'var(--color-black-30)',
      },
    },
    info: {
      default: {
        background: 'var(--color-purple-80)',
        border: 'var(--color-purple-80)',
        color: 'var(--color-white)',
        focusBorder: 'var(--color-blue-20)',
      },
      hover: {
        background: 'var(--color-blue-80)',
        border: 'var(--color-blue-80)',
      },
      pressed: {
        background: 'var(--color-blue-80)',
        border: 'var(--color-blue-20)',
      },
      focus: {
        border: 'var(--color-blue-20)',
      },
      disabled: {
        background: 'var(--color-black-40)',
        border: 'transparent',
        color: 'var(--color-white)',
      },
    },
    critical: {
      default: {
        background: 'var(--color-red-50)',
        border: 'var(--color-red-50)',
        color: 'var(--color-white)',
        focusBorder: 'var(--color-black-30)',
      },
      hover: {
        background: 'var(--color-blue-80)',
        border: 'var(--color-blue-80)',
      },
      pressed: {
        background: 'var(--color-blue-80)',
        border: 'var(--color-black-30)',
      },
      focus: {
        border: 'var(--color-black-30)',
      },
      disabled: {
        background: 'var(--color-black-40)',
        border: 'transparent',
        color: 'var(--color-white)',
      },
    },
  },
};

export const FilledButtonStyle = (
  variantType: TFilledButtonVariantType,
  size: TButtonSize = 'medium',
  variant: TButtonVariant = 'filled'
) => {
  const activeVariant = variant === 'outlined' ? 'outlined' : variantType;
  const config = BUTTON_CONFIG.variants[activeVariant];
  const padding = BUTTON_CONFIG.sizes[size];

  return {
    self: css`
      border-radius: 60px;
      border: 1px solid ${config.default.border};
      padding: ${padding};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      max-width: 100%;
      background: ${config.default.background};
      color: ${config.default.color};
      position: relative;

      & .icon {
        width: 20px;
        height: 20px;
        transition: none;
        fill: ${config.default.color};
      }

      &:disabled {
        background: ${config.disabled.background};
        border: 1px solid ${config.disabled.border};
        color: ${config.disabled.color};
        cursor: default;
        opacity: 0.6;
        box-shadow: none;

        & .icon {
          fill: ${config.disabled.color};
        }

        & .text:active {
          font-weight: 400;
        }
      }

      &:focus-visible {
        border: 1px solid ${config.focus.border};
        box-shadow: 0 0 0 1px ${config.focus.border};
        outline: none;
      }

      &:active {
        background: ${config.pressed.background};
        border: 1px solid ${config.pressed.border};
        box-shadow: 0 0 0 1px ${config.pressed.border};
        
        .text {
          font-weight: 700;
        }

        &:disabled {
          background: ${config.disabled.background};
          border: 1px solid ${config.disabled.border};
          color: ${config.disabled.color};
          cursor: default;
          opacity: 0.6;
          box-shadow: none;

          & .icon {
            fill: ${config.disabled.color};
          }

          .text {
            font-weight: 400;
          }
        }
      }

      &.active,
      &.selected {
        .text {
          font-weight: 700;
        }
      }

      &:not(:disabled) {
        &:hover {
          background: ${config.hover.background};
          border: 1px solid ${config.hover.border};
          box-shadow: 0 0 0 1px ${config.hover.border};
        }

        &:active {
          background: ${config.pressed.background};
          border: 1px solid ${config.default.focusBorder};
          box-shadow: 0 0 0 1px ${config.default.focusBorder};
          
          .text {
            font-weight: 700;
          }
        }
      }

      & .text {
        &::after {
          content: attr(data-text);
          display: block;
          font-weight: 700;
          height: 0;
          width: max-content;
          white-space: nowrap;
          overflow: hidden;
          visibility: hidden;
        }
      }


    `,
  };
};