/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import TokenRateLimitForm from './TokenRateLimitForm';

const TokenRateLimitProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Token Rate Limit - DoS/DoW"
            getValues={getValues}
            description="Protect against denial of service or denial of wallet attacks"
            formName="Token Rate Limit"
            Component={TokenRateLimitForm}
            isAdvancedMode={isAdvancedMode}
            summary={`The Token Rate Limit protection aims to secure genAI applications against potential denial of service or denial of wallet attacks. It does so by limiting the number of tokens that can be used within a certain timeframe.

The limitation can be applied in two ways:

1. Globally: You may count and limit the overall number of tokens processed via the application per time period.
2. Per Identity: You may also limit per time period the number of requests made by a unique user, identified either by user information (provided through your API request), or by the associated IP address.`}
        />
    )
}

export default TokenRateLimitProtection;