/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import React from 'react';
import { ProviderButtonStyle } from './ProviderButton.css';

type IProps = {
    children: React.ReactNode;
    selected?: boolean;
    css?: SerializedStyles;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ProviderButton: React.FC<IProps> = (props) => {
    const { children, selected, css, ...rest } = props;

    return (
        <button css={[ProviderButtonStyle.self, css]} className={selected ? 'selected' : ''} {...rest}>
            {children}
        </button>
    )
}

export default ProviderButton;