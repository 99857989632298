/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { Control, UseFormGetValues } from 'react-hook-form';
import { Icon, Text } from '../../../../../ui-kit';
import ProtectionCollapsible from '../ProtectionCollapsible/ProtectionCollapsible';
import { IProtectionFormProps, TDevelopers, TEmployees, THomeGrownApplications, TProtectionTextType } from '../protections_types';
import { ProtectionWrapperStyle } from './ProtectionWrapper.css';

type IProps = {
    control: Control<any>;
    getValues: UseFormGetValues<{
        content: TEmployees | TDevelopers | THomeGrownApplications;
    }>
    label: string;
    formName:
    keyof TDevelopers['prompt'] | keyof TDevelopers['response']
    | keyof TEmployees['prompt']
    | (keyof THomeGrownApplications['prompt'] | keyof THomeGrownApplications['response']);
    description: string;
    isAdvancedMode?: boolean;
    Component?: React.FC<IProtectionFormProps<any>>;
    summary?: string;
    excludePromptOrResponse?: TProtectionTextType;
    disableCheckbox?: boolean;
};

const ProtectionWrapper: React.FC<IProps> = (props) => {
    const { control, getValues, label, formName, description, isAdvancedMode, Component, summary, excludePromptOrResponse, disableCheckbox = false } = props;

    const promptFormName = `content.prompt.${formName}`
    const responseFormName = `content.response.${formName}`

    const promptProtection = excludePromptOrResponse === 'prompt' ? null : getValues(promptFormName as any);
    const responseProtection = excludePromptOrResponse === 'response' ? null : getValues(responseFormName as any)

    const top10 = useMemo(() => {
        if (promptProtection) return promptProtection.top10;
        if (responseProtection) return responseProtection.top10;
        return [];
    }, [promptProtection, responseProtection])

    return (
        <ProtectionCollapsible
            name={label}
            description={description}
            prompt={promptProtection ? promptProtection.enabled : undefined}
            response={responseProtection ? responseProtection.enabled : undefined}
            formName={formName}
            control={control}
            isExpandAllowed={Boolean(Component || summary)}
            top10={top10}
            disableCheckbox={disableCheckbox}
        >
            {summary && (
                <div css={[ProtectionWrapperStyle.container, ((responseProtection || promptProtection) && Component) ? ProtectionWrapperStyle.bottomLine : null]}>
                    <Text pre color='black-70'>{summary}</Text>
                </div>
            )}

            {responseProtection && promptProtection && <style>
                {`
                    .response {
                        padding-left: 20px;
                        border-left: 1px solid var(--color-black-20);
                    }
                    @container separateLine (width < 1320px){
                        .response {
                            padding-left: 0;
                            border-left: 0;

                            border-top: 1px solid var(--color-black-20);
                            padding-top: 20px;
                        }
                    }
                `}
            </style>}
            <div css={ProtectionWrapperStyle.promptResponseContainer}>
                {promptProtection && Component && (
                    <div css={ProtectionWrapperStyle.container}>
                        <div css={ProtectionWrapperStyle.title}>
                            <Icon iconSize={15} color="black" iconName='PSReplyAllRightIcon' />
                            <Text variant='smallBold'>Prompt</Text>
                        </div>
                        <Component
                            control={control}
                            formName={promptFormName}
                            protection={promptProtection}
                            isAdvancedMode={isAdvancedMode}
                        />
                    </div>
                )}

                {responseProtection && Component && (
                    <div css={ProtectionWrapperStyle.container} className="response">
                        <div css={ProtectionWrapperStyle.title}>
                            <Icon iconSize={15} color="black" iconName='PSReplyAllLeftIcon' />
                            <Text variant='smallBold'>Response</Text>
                        </div>
                        <Component
                            control={control}
                            formName={responseFormName}
                            protection={responseProtection}
                            isAdvancedMode={isAdvancedMode}
                        />
                    </div>
                )}
            </div>
        </ProtectionCollapsible>
    )
}

export default ProtectionWrapper;