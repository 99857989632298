/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import ModeratorForm from './ModeratorForm';

const ModeratorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Moderator"
            getValues={getValues}
            description="Block unsafe or harmful content"
            formName="Moderator"
            Component={ModeratorForm}
            isAdvancedMode={isAdvancedMode}
            summary={`The moderator is an LLM-powered safeguard designed to prevent the model from generating harmful or offensive content in its responses. It serves as a safety filter, ensuring that the model's outputs remain appropriate and free from unwanted content.`}
        />
    )
}

export default ModeratorProtection;