import { AllProtectionKeys } from "./protections_types";
import { colorTheme, ColorThemeKeys } from "../../../../styles";

export const protectionKeysColorMap: Record<typeof AllProtectionKeys[number], string> = {
    'Prompt Leak Detector': colorTheme['purple'][20],
    'Token Limitation': colorTheme['purple'][30],
    Regex: colorTheme['purple'][40],
    Secrets: colorTheme['purple'][60],
    'Sensitive Data': colorTheme['purple'][80],
    Moderator: colorTheme['blue'][20],
    "Code Detector": colorTheme['blue'][30],
    'Token Rate Limit': colorTheme['blue'][40],
    Sentiment: colorTheme['blue'][50],
    'Prompt Injection Engine': colorTheme['red'][20],
    "URLs Detector": colorTheme['red'][30],
    'Jailbreak Detector': colorTheme['red'][40],
    "Strict Format": colorTheme['red'][50],
    'Language Detector': colorTheme['aqua'][20],
    'Topics Detector': colorTheme['aqua'][30],
    'Data Privacy Guidelines': colorTheme['aqua'][50],
    'Prompt Hardening': colorTheme['aqua'][60],
    'Sanitizer Model': colorTheme['yellow'][50],
    'Custom Moderator': colorTheme['orange'][50],
    'Harmful Content Moderator': colorTheme['orange'][40],
}

export const protectionKeysThemeColorNameMap: Record<typeof AllProtectionKeys[number], ColorThemeKeys> = {
    'Prompt Leak Detector': 'purple-20',
    'Token Limitation': 'purple-30',
    Regex: 'purple-40',
    Secrets: 'purple-60',
    'Sensitive Data': 'purple-80',
    Moderator: 'blue-20',
    "Code Detector": 'blue-30',
    'Token Rate Limit': 'blue-40',
    Sentiment: 'blue-50',
    'Prompt Injection Engine': 'red-20',
    "URLs Detector": 'red-30',
    'Jailbreak Detector': 'red-40',
    "Strict Format": 'red-50',
    'Language Detector': 'aqua-20',
    'Topics Detector': 'aqua-30',
    'Data Privacy Guidelines': 'aqua-50',
    'Prompt Hardening': 'aqua-60',
    'Sanitizer Model': 'yellow-50',
    'Custom Moderator': 'orange-50',
    'Harmful Content Moderator': 'orange-40'
}