/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { Icon } from '../../ui-kit'
import {IProps as IconProps} from '../../ui-kit/Icon/Icon';
import { PSCircledIconStyle } from './PSCircledIcon.css';
import {ColorThemeKeys} from "../../styles";

type IProps = {
    iconProps: IconProps;
    borderColor?: ColorThemeKeys;
    iconSize?: number;
}

const PSCircledIcon: FC<IProps> = (props) => {
    const {iconProps, borderColor = 'black',iconSize = 20} = props;

    return (
        <div css={PSCircledIconStyle.self(iconSize*2, borderColor)}>
            <Icon
                {...iconProps}
                iconSize={iconSize}
            />
        </div>
    )
}

export default PSCircledIcon;