/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useMemo, useLayoutEffect } from 'react';
import Chip from '../../../Chip/Chip';
import Text from '../../../Text/Text';
import { ChipsStyle } from './Chips.css';
import Popover from '../../../Popover/Popover';

type IProps = {
    value: string | string[];
    customPlusNComponent?: React.ReactNode;
    basedOnWidth?: boolean;
    flexWrap?: boolean;
    renderChip?: (value: string) => React.ReactNode;
    chipProps?: Record<string, any>;
    onLimitCalculated?: (newLimit: number) => void;
    addedWidth?: number;
}

const Chips: React.FC<IProps> = (props) => {
    const { 
        value,
        customPlusNComponent,
        addedWidth = 25,
        basedOnWidth = true, 
        flexWrap = false,
        renderChip,
        chipProps = {},
        onLimitCalculated,
    } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const [limit, setLimit] = useState<number>(0);
    const [isLimitCalculated, setIsLimitCalculated] = useState<boolean>(false);

    const sortedValue = useMemo(() => {
        if (!value) return [];
        if (!Array.isArray(value)) return [value];
        else if (Array.isArray(value)) {
            return [...value].sort((a, b) => a.length - b.length);
        }
        return [];
    }, [value]);

    useLayoutEffect(() => {
        const updateLimitBasedOnWidth = () => {
            if (!basedOnWidth) {
                setLimit(sortedValue.length);
                setIsLimitCalculated(true);
                return;
            }
            if (sortedValue.length === 0) return;
            if (sortedValue.length === 1) {
                setLimit(1);
                setIsLimitCalculated(true);
                return;
            }
            if (containerRef.current && sortedValue.length > 0) {
                const maxWidth = containerRef.current.offsetWidth - 2;
                let totalWidth = 0;
                let newLimit = 0;

                const tempChip = document.createElement("div");
                document.body.appendChild(tempChip);

                for (let i = 0; i < sortedValue.length; i++) {
                    tempChip.innerText = sortedValue[i];
                    tempChip.style.display = "inline-block";

                    const chipWidth = tempChip.offsetWidth + addedWidth;
                    if (totalWidth + chipWidth < maxWidth) {
                        totalWidth += chipWidth;
                        newLimit++;
                    } else {
                        break;
                    }
                }

                document.body.removeChild(tempChip);

                setLimit(newLimit);
                if (onLimitCalculated) onLimitCalculated(newLimit);
                setIsLimitCalculated(true);
            }
        };

        updateLimitBasedOnWidth();
    }, [limit, sortedValue]);

    const chipsToDisplay = useMemo(() => {
        return sortedValue.slice(0, limit);
    }, [limit, sortedValue]);

    const chipsToHide = useMemo(() => {
        return sortedValue.slice(limit);
    }, [limit, sortedValue]);


    //TODO: refactor bottom popup logic
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const renderDefaultChip = (value: string) => (
        <Chip
            key={value}
            css={ChipsStyle.chip}
            size="small"
            label={<Text variant='small' ellipsis>{value}</Text>}
            variant="outlined"
            {...chipProps}
        />
    );

    const PlusNComponent = customPlusNComponent || <Chip
        size="small"
        label={
            <Text variant='small' ellipsis>+{chipsToHide.length}</Text>
        }
        variant="outlined"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
    />

    return (
        <>
            <div ref={containerRef} css={ChipsStyle.self}>
                {isLimitCalculated &&
                    <>
                        <div css={ChipsStyle.firstChipsContainer(flexWrap)}>
                            {chipsToDisplay.map((x: string) =>
                                renderChip ? renderChip(x) : renderDefaultChip(x)
                            )}
                        </div>

                        {chipsToHide.length > 0 && PlusNComponent}
                    </>
                }
            </div>

            {chipsToHide.length > 0 && <Popover
                css={ChipsStyle.popover}
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div css={ChipsStyle.moreChipsContainer}>
                    {chipsToHide.map((x: string) =>
                        renderChip ? renderChip(x) : renderDefaultChip(x)
                    )}
                </div>
            </Popover>}
        </>
    );
}

export default Chips;


