/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { FC } from "react";
import { PSDoubleOverviewStyle } from './PSDoubleOverview.css';
import {
    Text,
    PSEmptyState,
    Circle,
} from "../index";
import {TOverviewVariants} from "../PSOverview/types";
import SingleSide from "./SingleSide";
import { IProps as TSingleSide } from './SingleSide'

type IProps = {
    variant: Exclude<TOverviewVariants, 'pointyDark'>;
    leftData: TSingleSide;
    rightData: TSingleSide;
    isLoading?: boolean;
    isError?: boolean;
    errorOnClick?: () => void;
    minWidth?: number;
    maxWidth?: number;
    maxContentWidth?: number;
    containerCss?:SerializedStyles;
}

const PSDoubleOverview: FC<IProps> = (props) => {
    const { variant, leftData, rightData, isLoading, minWidth, maxWidth, maxContentWidth, containerCss, isError = false, errorOnClick } = props;

    const styles = PSDoubleOverviewStyle(variant);

    if (isError) return (
        <div css={styles.errorContainer}>
            <PSEmptyState
                variant='error'
                title='Something went wrong'
                size='small'
                mode='horizontal'
                description={<Text variant='text' color='black-70' onClick={errorOnClick} showUnderlineOnClick>Click here to try again</Text>}
            />
        </div>
    )

    const skeletonColor = variant === 'dark' ? 'white' : 'black-30';

    return (
        <div css={[styles.self(minWidth, maxWidth), containerCss]}>
            <Circle size={215} circleCss={styles.glow}/>
            <div css={styles.contentContainer(maxContentWidth)}>
                <SingleSide {...leftData} isLoading={isLoading} skeletonColor={skeletonColor}/>
                <SingleSide {...rightData} isLoading={isLoading} skeletonColor={skeletonColor}/>
            </div>
        </div>
    )
}

export default PSDoubleOverview;