/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import PromptHardeningForm from './PromptHardeningForm';

const PromptHardeningProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    if (!isAdvancedMode) return null;

    return (
        <ProtectionWrapper
            control={control}
            label="Prompt Hardening"
            getValues={getValues}
            description="Separates system and user prompts to prevent prompt injection and jailbreak attacks."
            formName="Prompt Hardening"
            Component={PromptHardeningForm}
            summary={`"Prompt Hardening" protects your GenAI application by isolating system prompts from user input using techniques like Sequence Enclosure and XML Tagging, reducing the risk of prompt injection and jailbreaks.`}
        />
    )
}

export default PromptHardeningProtection;