/** @jsxImportSource @emotion/react */
import React, { FC } from "react";
import { IconAndTextStyle } from './IconAndText.css';
import {Icon, Text} from "../../../index";
import {formatNumberWithCommasAndMaxValue} from "../../../../utils";
import {PSIconNames} from "../../../Icon/Icon";
import {ColorThemeKeys} from "../../../../styles";

type IProps = {
    value: number | string | undefined;
    iconName: PSIconNames;
    iconColor?: ColorThemeKeys;
    textColor?: ColorThemeKeys;
    iconSize?: number;
    shouldCircleIcon?: boolean;
}

const IconAndText: FC<IProps> = (props) => {
    const {value, iconColor, iconName, shouldCircleIcon, textColor, iconSize = 20} = props;

    const valueToUse = typeof value === "number" ? formatNumberWithCommasAndMaxValue(value) : value;

    const IconMarkup = <Icon iconName={iconName} color={iconColor} iconSize={iconSize}/>

    return (
        <div css={IconAndTextStyle.self}>
            {shouldCircleIcon ?
                <div css={IconAndTextStyle.iconWrapper(iconSize + 10, iconColor)}>{IconMarkup}</div>
                : IconMarkup}
            <Text color={textColor}>{valueToUse}</Text>
        </div>
    )
}

export default IconAndText;