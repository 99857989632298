/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import CodeMirror, { ReactCodeMirrorProps } from '@uiw/react-codemirror';
import PSButton from '../PSButton/PSButton';
import { PSCodeMirrorStyle } from './PSCodeMirror.css';
import { codeMirrorTheme } from '../../styles';


type IProps = {
    copyButton?: boolean;
    copyText?: string;
} & ReactCodeMirrorProps;

const PSCodeMirror: React.FC<IProps> = (props) => {
    const { copyButton, copyText, value, ...others } = props;

    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (!isCopied) return;
        const timeout = setTimeout(() => setIsCopied(false), 10000);
        return () => clearTimeout(timeout);
    }, [isCopied]);


    const copyToClipboard = () => {
        navigator.clipboard.writeText(copyText || value || '');
        setIsCopied(true);
    }

    return (
        <div css={PSCodeMirrorStyle.self}>
            {copyButton &&
                <PSButton
                    variant='filled'
                    size='small'
                    iconName={isCopied ? '' : 'PSCopyIcon'}
                    css={PSCodeMirrorStyle.copyButton}
                    onClick={copyToClipboard}
                >
                    {isCopied ? 'Copied!' : ''}
                </PSButton>}
            <CodeMirror theme={codeMirrorTheme} value={value} {...others} />
        </div>
    )
}

export default PSCodeMirror;