/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import HarmfulContentModeratorForm from './HarmfulContentModeratorForm';

const HarmfulContentModeratorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Harmful Content Moderator"
            getValues={getValues}
            description="Block unsafe, toxic or harmful content"
            formName="Harmful Content Moderator"
            Component={HarmfulContentModeratorForm}
            isAdvancedMode={isAdvancedMode}
            summary={`This updated version of the moderator is designed to prevent the inclusion of harmful or offensive content in your prompts or responses, including certain regional slang.`}
        />
    )
}

export default HarmfulContentModeratorProtection;