import { create } from 'zustand';

interface TableHelpersState {
    tableHeight: number | undefined;
    setTableHeight: (value: number) => void;
}

const useTableHelpersStore = create<TableHelpersState>((set) => ({
    tableHeight: undefined,
    setTableHeight: (newHeight) => set(() => ({ tableHeight: newHeight })),
}));

export default useTableHelpersStore;